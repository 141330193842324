import { Menu, Tab, TabGroup, TabList } from '@headlessui/react'
import clsx from 'clsx'
import { Fragment, ReactNode } from 'react'
import { CrewChangesDateFilteringOption } from 'utils/crewChanges/types'
import { Mobile, TabletAndAbove } from 'utils/getResponsiveLayout'
import ActionMenu from '~components/ui/ActionMenu/ActionMenu'

interface Props {
  onSelected: (filteringOption: CrewChangesDateFilteringOption) => void
  options: {
    value: CrewChangesDateFilteringOption
    label: string | ReactNode
  }[]
}

export default function CrewChangesDateFilter({ onSelected, options }: Props) {
  return (
    <>
      <TabletAndAbove>
        <div className="bg-white rounded-xl border-2 p-1 text-sm text-gray-700">
          <TabGroup
            onChange={(index: number) => {
              onSelected(options[index].value)
            }}
          >
            <TabList>
              {options.map((op) => {
                return (
                  <Tab as={Fragment} key={op.value}>
                    {({ selected }) => (
                      <button
                        type="button"
                        disabled={selected}
                        className={clsx(
                          'rounded-md mx-1 p-1 px-2',
                          selected && 'bg-gray-200',
                          !selected && 'bg-white'
                        )}
                      >
                        <span
                          className={clsx(
                            selected && 'font-bold text-black',
                            !selected && 'bg-white'
                          )}
                        >
                          {op.label}
                        </span>
                      </button>
                    )}
                  </Tab>
                )
              })}
            </TabList>
          </TabGroup>
        </div>
      </TabletAndAbove>
      <Mobile>
        <ActionMenu
          customToggleButton={
            <Menu.Button className="flex flex-col items-center rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 focus-within:opacity-100 text-gray-800 cursor-pointer h-12">
              <span className="py-1 px-3 h-2">&bull;</span>
              <span className="py-1 px-3 h-2">&bull;</span>
              <span className="py-1 px-3 h-2">&bull;</span>
            </Menu.Button>
          }
          items={[
            {
              data: options.map((op) => {
                return {
                  children: `${op.label}`,
                  onClick: () => onSelected(op.value),
                }
              }),
            },
          ]}
        />
      </Mobile>
    </>
  )
}
