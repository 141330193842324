import { useLoadCurrencyRatesQuery } from 'api/info.generated'
import { useCallback, useContext, useMemo } from 'react'
import { UserContext } from '~context/UserContext'

export type Rates = { [currency: string]: number }

const defaultRates: Rates = {
  EUR: 1,
  USD: 1,
  PHP: 56,
}

export default function useCurrencyConvert(customRates?: Rates) {
  const { isAuthenticated, user } = useContext(UserContext)
  const { data: ratesData } = useLoadCurrencyRatesQuery({
    skip: !isAuthenticated,
  })
  const tenantCurrency = user.tenant.preferences.preferredCurrency

  // holds rates related to USD as our base currency. Current rates as fetched from the API will be used by default, but you can pass in custom rates to use instead.
  const rates = useMemo(
    (): { [currency: string]: number } => customRates ?? ratesData?.currencyRates ?? defaultRates,
    [ratesData?.currencyRates, customRates]
  )

  const convert = useCallback(
    (amount: number, currency: string, targetCurrency: string) => {
      if (currency === targetCurrency) {
        return amount
      }

      // All conversions are relative to USD (so USD conversion rate === 1)
      return (amount / rates[currency]) * rates[targetCurrency]
    },
    [rates]
  )

  const convertToTenantCurrency = useCallback(
    (amount: number, currency: string) => {
      if (!tenantCurrency) {
        return amount
      }
      return convert(amount, currency, tenantCurrency)
    },
    [convert, tenantCurrency]
  )

  return {
    convert,
    convertToTenantCurrency,
    tenantCurrency,
    rates,
  }
}
