/* eslint-disable */
import * as Types from './types.generated'

import { gql } from '@apollo/client'
import {
  CompleteCrewChangeFragmentDoc,
  CompleteSeamanDetailsFragmentDoc,
} from './crew-changes-fragments.generated'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type LoadCrewChangesQueryVariables = Types.Exact<{
  completed?: Types.InputMaybe<Types.Scalars['Boolean']['input']>
  cancelled?: Types.InputMaybe<Types.Scalars['Boolean']['input']>
  archived?: Types.InputMaybe<Types.Scalars['Boolean']['input']>
  endAt: Types.Scalars['DateTime']['input']
  startAt: Types.Scalars['DateTime']['input']
  etd: Types.Scalars['DateTime']['input']
}>

export type LoadCrewChangesQuery = {
  crewChanges: Array<{
    activeCrewOperatorType: Types.ActiveCrewOperatorType
    activeCrewSupervisorType: Types.ActiveCrewSupervisorType
    backupCrewOperatorId: string | null
    backupCrewSupervisorId: string | null
    primaryCrewSupervisorName: string | null
    backupCrewSupervisorName: string | null
    bucketId: string
    completed: boolean
    archived: boolean
    ETA: string
    ETB: string
    ETD: string
    vesselScheduleSource: Types.VesselScheduleSource
    manningAgencies: Array<string>
    numOffsigners: number
    numOnsigners: number
    totalNumSeafarersIncludingPromotions: number
    portAgents: Array<string>
    portName: string | null
    portTimezoneOlson: string | null
    primaryCrewOperatorId: string | null
    primaryCrewSupervisorId: string | null
    started: boolean | null
    vesselId: string
    vesselName: string
    isScheduleCancelled: boolean
    flightBookingsLTD: string | null
    bookedFlightBookings: number
    ticketedFlightBookings: number
    openFlightBookings: number
    primaryCrewOperatorName: string | null
    backupCrewOperatorName: string | null
  }>
}

export type AddCrewListEntryCommentMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input']
  comment: Types.Scalars['String']['input']
}>

export type AddCrewListEntryCommentMutation = {
  crewListEntry: { addComment: { id: string; comments: Array<{ id: string; comment: string }> } }
}

export type AddCrewChangeVisaRequirementsMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input']
  requirements:
    | Array<Types.AddCrewChangeVisaRequirementsInput>
    | Types.AddCrewChangeVisaRequirementsInput
}>

export type AddCrewChangeVisaRequirementsMutation = {
  crewListEntry: { addVisaRequirements: { id: string } }
}

export type AddCrewChangeCovidRestrictionMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input']
  restriction: Types.CrewChangeCovidRestrictionInput
}>

export type AddCrewChangeCovidRestrictionMutation = {
  crewListEntry: { addCovidRectriction: { id: string } }
}

export type CrewChangeOverviewFieldsFragment = {
  activeCrewOperatorType: Types.ActiveCrewOperatorType
  activeCrewSupervisorType: Types.ActiveCrewSupervisorType
  backupCrewOperatorId: string | null
  backupCrewSupervisorId: string | null
  primaryCrewSupervisorName: string | null
  backupCrewSupervisorName: string | null
  bucketId: string
  completed: boolean
  archived: boolean
  ETA: string
  ETB: string
  ETD: string
  vesselScheduleSource: Types.VesselScheduleSource
  manningAgencies: Array<string>
  numOffsigners: number
  numOnsigners: number
  totalNumSeafarersIncludingPromotions: number
  portAgents: Array<string>
  portName: string | null
  portTimezoneOlson: string | null
  primaryCrewOperatorId: string | null
  primaryCrewSupervisorId: string | null
  started: boolean | null
  vesselId: string
  vesselName: string
  isScheduleCancelled: boolean
  flightBookingsLTD: string | null
  bookedFlightBookings: number
  ticketedFlightBookings: number
  openFlightBookings: number
  primaryCrewOperatorName: string | null
  backupCrewOperatorName: string | null
}

export type GetCrewChangesLastDataSyncQueryVariables = Types.Exact<{ [key: string]: never }>

export type GetCrewChangesLastDataSyncQuery = {
  crewChangesLastDataSync: { createdAt: string; type: Types.DataSyncType } | null
}

export type GetCrewChangeStartEmailQueryVariables = Types.Exact<{
  bucketId: Types.Scalars['UUID']['input']
  params?: Types.InputMaybe<Types.StartCrewChangeEmailParams>
}>

export type GetCrewChangeStartEmailQuery = {
  crewChangeInfo: {
    startEmail: {
      seamanBooksAvailabilityStatus: Types.DocumentsAvailableStatus
      passportsAvailabilityStatus: Types.DocumentsAvailableStatus
      visasAvailabilityStatus: Types.DocumentsAvailableStatus
      email: string
    }
  }
}

export type LoadCrewChangeQueryVariables = Types.Exact<{
  bucketId: Types.Scalars['UUID']['input']
  startAt?: Types.InputMaybe<Types.Scalars['DateTime']['input']>
  endAt?: Types.InputMaybe<Types.Scalars['DateTime']['input']>
}>

export type LoadCrewChangeQuery = {
  crewChange: {
    bucketId: string
    completed: boolean
    archived: boolean
    temporary: boolean
    id: string
    startOn: string
    started: boolean
    vesselSchedule: {
      id: string
      sourceId: string | null
      cancelled: boolean
      ETA: string
      ETB: string
      ETD: string
      source: Types.VesselScheduleSource
      updatedAt: string
      crewChangeLinkage: { bucketId: string | null; isLinked: boolean } | null
      seaport: {
        id: string
        name: string
        nameAlpha2: string | null
        alternateNames: Array<string>
        countryAlpha2: string
        locode: string
        latitude: number
        longitude: number
        timezoneOlson: string | null
        quarantineRequirements: Array<{
          id: string
          vaccinationStatus: Types.VaccinationStatus
          quarantineTime: number
        }>
        covidTestingRequirements: Array<{
          id: string
          applicableTo: Types.VaccinationStatus
          timeReference: Types.TimeReference
          type: Array<string>
          travelPurpose: Types.TravelPurpose
        }>
      }
      updatedBy: {
        id: string
        email: string
        firstName: string | null
        lastName: string | null
        role: Types.UserRole
        phoneNumber: string | null
        city: string | null
        countryAlpha2: string | null
        timezoneOlson: string | null
        emailSignature: string | null
        fernandHash: string | null
      } | null
    }
    vessel: {
      activeCrewOperatorType: Types.ActiveCrewOperatorType
      activeCrewSupervisorType: Types.ActiveCrewSupervisorType
      id: string
      vesselScheduleSource: Types.VesselScheduleSource
      charterName: string
      imo: number
      vesselCommunicationDetails: Array<{
        id: string
        type: Types.CommunicationType
        value: string
      }>
      manningAgencyUsersForVessels: Array<{
        manningAgencyUser: {
          manningAgencyId: string
          user: {
            id: string
            email: string
            firstName: string | null
            lastName: string | null
            role: Types.UserRole
            phoneNumber: string | null
            city: string | null
            countryAlpha2: string | null
            timezoneOlson: string | null
            emailSignature: string | null
            fernandHash: string | null
          }
        }
      }>
      primaryCrewSupervisor: {
        id: string
        email: string
        firstName: string | null
        lastName: string | null
        role: Types.UserRole
        phoneNumber: string | null
        city: string | null
        countryAlpha2: string | null
        timezoneOlson: string | null
        emailSignature: string | null
        fernandHash: string | null
      } | null
      backupCrewSupervisor: {
        id: string
        email: string
        firstName: string | null
        lastName: string | null
        role: Types.UserRole
        phoneNumber: string | null
        city: string | null
        countryAlpha2: string | null
        timezoneOlson: string | null
        emailSignature: string | null
        fernandHash: string | null
      } | null
      primaryCrewOperator: {
        id: string
        email: string
        firstName: string | null
        lastName: string | null
        role: Types.UserRole
        phoneNumber: string | null
        city: string | null
        countryAlpha2: string | null
        timezoneOlson: string | null
        emailSignature: string | null
        fernandHash: string | null
      } | null
      backupCrewOperator: {
        id: string
        email: string
        firstName: string | null
        lastName: string | null
        role: Types.UserRole
        phoneNumber: string | null
        city: string | null
        countryAlpha2: string | null
        timezoneOlson: string | null
        emailSignature: string | null
        fernandHash: string | null
      } | null
      additionalCrewOperatorsForVessel: Array<{
        crewOperator: {
          id: string
          email: string
          firstName: string | null
          lastName: string | null
          role: Types.UserRole
          phoneNumber: string | null
          city: string | null
          countryAlpha2: string | null
          timezoneOlson: string | null
          emailSignature: string | null
          fernandHash: string | null
        }
      }>
      crewManager: {
        id: string
        email: string
        firstName: string | null
        lastName: string | null
        role: Types.UserRole
        phoneNumber: string | null
        city: string | null
        countryAlpha2: string | null
        timezoneOlson: string | null
        emailSignature: string | null
        fernandHash: string | null
      } | null
    }
    seamen: Array<{
      id: string
      role: Types.CrewChangeRole
      active: boolean
      isSearchDeferred: boolean
      seamanId: string
      confirmationStatuses: Array<{
        id: string
        status: Types.SeamanConfirmationStatus
        user: {
          id: string
          email: string
          firstName: string | null
          lastName: string | null
          role: Types.UserRole
          phoneNumber: string | null
          city: string | null
          countryAlpha2: string | null
          timezoneOlson: string | null
          emailSignature: string | null
          fernandHash: string | null
        }
      }>
      crewChangeVisaRequirements: Array<{
        id: string
        createdAt: string
        isVisaRequired: boolean
        region: string
        visaRequiredOn: Types.VisaOrCovidTestRequiredOn
        additionalComments: string
        createdBy: {
          id: string
          email: string
          firstName: string | null
          lastName: string | null
          role: Types.UserRole
          phoneNumber: string | null
          city: string | null
          countryAlpha2: string | null
          timezoneOlson: string | null
          emailSignature: string | null
          fernandHash: string | null
        }
      }>
      manningAgency: { id: string; name: string } | null
      seaman: {
        id: string
        firstName: string
        lastName: string
        nationalityIso: string
        rank: number | null
        birthday: string | null
        placeOfBirth: string | null
        firstVaccinationDate: string | null
        firstVaccinationBrand: string | null
        secondVaccinationDate: string | null
        secondVaccinationBrand: string | null
        updatedAt: string
        preferredAirport: {
          isHomeAirport: boolean
          airport: {
            id: string
            alternativeIatas: Array<string>
            city: string
            countryAlpha2: string
            iata: string
            name: string
            timezoneOlson: string | null
            type: Types.AirportType | null
          }
        } | null
        manningAgency: { id: string } | null
        flightBookings: Array<{
          id: string
          active: boolean
          monitored: boolean
          ticketLine: string | null
          comment: string | null
          pnr: string | null
          status: Types.FlightBookingStatus
          amadeusSessionId: string | null
          managedByTravelTeam: boolean
          isAmadeus: boolean
          lastTicketingTime: string | null
          lastTicketingTimeSource: Types.LastTicketingTimeSource
          pendingUpdatedByTravelAgentNotification: boolean
          switchForIdenticalCheaperOfferAutomatically: boolean
          switchForSimilarCheaperOfferAutomatically: boolean
          ticketedAt: string | null
          bucketId: string | null
          autoTicket: boolean
          travelOfficeConfigurationId: string | null
          flight: {
            id: string
            updatedAt: string
            createdAt: string
            flightHash: string
            majorityCarrier: string | null
            travelOfficeConfigurationId: string
            validatingCarrier: string | null
            segments: Array<{
              id: string
              flightNumber: number
              index: number
              departure: {
                timezoneOlson: string | null
                time: string
                name: string | null
                iata: string
                countryAlpha2: string | null
                city: string | null
                date: string
                terminal: string | null
              }
              arrival: {
                timezoneOlson: string | null
                time: string
                name: string | null
                iata: string
                countryAlpha2: string | null
                city: string | null
                date: string
                terminal: string | null
              }
              marketingCarrier: {
                id: string
                createdAt: string
                updatedAt: string
                iata: string
                name: string
                logo: string | null
              } | null
              operatingCarrier: {
                id: string
                createdAt: string
                updatedAt: string
                iata: string
                name: string
                logo: string | null
              } | null
            }>
            travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
          }
          offer: {
            id: string
            amount: number
            tax: number | null
            passengerType: Types.PassengerType | null
            currency: string
            createdAt: string
            updatedAt: string
            flightId: string
            amadeusSessionId: string | null
            segmentFares: Array<{
              availableSeats: number | null
              fareTypes: Array<Types.FareType>
              fareBasisCode: string
              flightSegmentId: string
              bookingClass: string | null
              baggageDetails: {
                freeBagAllowance: number | null
                freeBagAllowanceType: Types.FreeBagAllowanceType | null
              }
            }>
            rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
            minirules: {
              beforeDeparture: {
                changeAllowed: boolean | null
                changeMaxPenaltyAmount: number | null
                refundAllowed: boolean | null
                refundMaxPenaltyAmount: number | null
                revalidationAllowed: boolean | null
                revalidationMaxPenaltyAmount: number | null
                isoTicketValidityDate: string | null
                taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
              } | null
              beforeDepartureNoShow: {
                changeAllowed: boolean | null
                changeMaxPenaltyAmount: number | null
                refundAllowed: boolean | null
                refundMaxPenaltyAmount: number | null
                revalidationAllowed: boolean | null
                revalidationMaxPenaltyAmount: number | null
                isoTicketValidityDate: string | null
                taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
              } | null
              afterDeparture: {
                changeAllowed: boolean | null
                changeMaxPenaltyAmount: number | null
                refundAllowed: boolean | null
                refundMaxPenaltyAmount: number | null
                revalidationAllowed: boolean | null
                revalidationMaxPenaltyAmount: number | null
                isoTicketValidityDate: string | null
                taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
              } | null
              afterDepartureNoShow: {
                changeAllowed: boolean | null
                changeMaxPenaltyAmount: number | null
                refundAllowed: boolean | null
                refundMaxPenaltyAmount: number | null
                revalidationAllowed: boolean | null
                revalidationMaxPenaltyAmount: number | null
                isoTicketValidityDate: string | null
                taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
              } | null
            } | null
            taxDetails: Array<{
              amount: number
              code: string | null
              isNonRefundable: boolean | null
            }> | null
          }
          finalOffer: {
            amount: number
            currency: Types.Currency
            exchangeRate: { ratesToUsd: unknown } | null
          } | null
          seaman: {
            id: string
            firstName: string
            lastName: string
            nationalityIso: string
            rank: number | null
            birthday: string | null
            placeOfBirth: string | null
            firstVaccinationDate: string | null
            firstVaccinationBrand: string | null
            secondVaccinationDate: string | null
            secondVaccinationBrand: string | null
            updatedAt: string
          }
          ticketLineData: { documentNumber: string } | null
          splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
        }>
        seamanBooks: Array<{
          id: string
          bookNumber: string
          issueAt: string | null
          expiryAt: string | null
          isFlagStateSeamanBook: boolean
          nationalityAlpha2: string | null
        }>
        seamanPassports: Array<{
          id: string
          passportNumber: string
          passportIssuePlace: string | null
          passportNationalityIso: string
          passportIssueAt: string
          passportExpiryAt: string
        }>
        seamanVisas: Array<{
          id: string
          visaNumber: string
          visaName: string
          issueAt: string
          expiryAt: string
        }>
        seamanContracts: Array<{
          id: string
          hanseaticAssignmentId: number
          contractFrom: string
          contractUntil: string
        }>
        seamanRank: { name: string; sortOrder: number | null } | null
        seamanCommunications: Array<{ id: string; type: string; value: string | null }>
      }
    }>
    crewChangeSeamanDetail: Array<{
      id: string
      sourceId: string | null
      embarkationAt: string
      disembarkationAt: string
      contractEndAt: string | null
      role: Types.CrewChangeRole
      crewHash: string
      seamanId: string
    }> | null
    nearestAirport: {
      id: string
      alternativeIatas: Array<string>
      city: string
      countryAlpha2: string
      iata: string
      name: string
      timezoneOlson: string | null
      type: Types.AirportType | null
    }
    promotedSeamen: Array<{
      id: string
      role: Types.CrewChangeRole
      active: boolean
      isSearchDeferred: boolean
      seamanId: string
      confirmationStatuses: Array<{
        id: string
        status: Types.SeamanConfirmationStatus
        user: {
          id: string
          email: string
          firstName: string | null
          lastName: string | null
          role: Types.UserRole
          phoneNumber: string | null
          city: string | null
          countryAlpha2: string | null
          timezoneOlson: string | null
          emailSignature: string | null
          fernandHash: string | null
        }
      }>
      crewChangeVisaRequirements: Array<{
        id: string
        createdAt: string
        isVisaRequired: boolean
        region: string
        visaRequiredOn: Types.VisaOrCovidTestRequiredOn
        additionalComments: string
        createdBy: {
          id: string
          email: string
          firstName: string | null
          lastName: string | null
          role: Types.UserRole
          phoneNumber: string | null
          city: string | null
          countryAlpha2: string | null
          timezoneOlson: string | null
          emailSignature: string | null
          fernandHash: string | null
        }
      }>
      manningAgency: { id: string; name: string } | null
      seaman: {
        id: string
        firstName: string
        lastName: string
        nationalityIso: string
        rank: number | null
        birthday: string | null
        placeOfBirth: string | null
        firstVaccinationDate: string | null
        firstVaccinationBrand: string | null
        secondVaccinationDate: string | null
        secondVaccinationBrand: string | null
        updatedAt: string
        preferredAirport: {
          isHomeAirport: boolean
          airport: {
            id: string
            alternativeIatas: Array<string>
            city: string
            countryAlpha2: string
            iata: string
            name: string
            timezoneOlson: string | null
            type: Types.AirportType | null
          }
        } | null
        manningAgency: { id: string } | null
        flightBookings: Array<{
          id: string
          active: boolean
          monitored: boolean
          ticketLine: string | null
          comment: string | null
          pnr: string | null
          status: Types.FlightBookingStatus
          amadeusSessionId: string | null
          managedByTravelTeam: boolean
          isAmadeus: boolean
          lastTicketingTime: string | null
          lastTicketingTimeSource: Types.LastTicketingTimeSource
          pendingUpdatedByTravelAgentNotification: boolean
          switchForIdenticalCheaperOfferAutomatically: boolean
          switchForSimilarCheaperOfferAutomatically: boolean
          ticketedAt: string | null
          bucketId: string | null
          autoTicket: boolean
          travelOfficeConfigurationId: string | null
          flight: {
            id: string
            updatedAt: string
            createdAt: string
            flightHash: string
            majorityCarrier: string | null
            travelOfficeConfigurationId: string
            validatingCarrier: string | null
            segments: Array<{
              id: string
              flightNumber: number
              index: number
              departure: {
                timezoneOlson: string | null
                time: string
                name: string | null
                iata: string
                countryAlpha2: string | null
                city: string | null
                date: string
                terminal: string | null
              }
              arrival: {
                timezoneOlson: string | null
                time: string
                name: string | null
                iata: string
                countryAlpha2: string | null
                city: string | null
                date: string
                terminal: string | null
              }
              marketingCarrier: {
                id: string
                createdAt: string
                updatedAt: string
                iata: string
                name: string
                logo: string | null
              } | null
              operatingCarrier: {
                id: string
                createdAt: string
                updatedAt: string
                iata: string
                name: string
                logo: string | null
              } | null
            }>
            travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
          }
          offer: {
            id: string
            amount: number
            tax: number | null
            passengerType: Types.PassengerType | null
            currency: string
            createdAt: string
            updatedAt: string
            flightId: string
            amadeusSessionId: string | null
            segmentFares: Array<{
              availableSeats: number | null
              fareTypes: Array<Types.FareType>
              fareBasisCode: string
              flightSegmentId: string
              bookingClass: string | null
              baggageDetails: {
                freeBagAllowance: number | null
                freeBagAllowanceType: Types.FreeBagAllowanceType | null
              }
            }>
            rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
            minirules: {
              beforeDeparture: {
                changeAllowed: boolean | null
                changeMaxPenaltyAmount: number | null
                refundAllowed: boolean | null
                refundMaxPenaltyAmount: number | null
                revalidationAllowed: boolean | null
                revalidationMaxPenaltyAmount: number | null
                isoTicketValidityDate: string | null
                taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
              } | null
              beforeDepartureNoShow: {
                changeAllowed: boolean | null
                changeMaxPenaltyAmount: number | null
                refundAllowed: boolean | null
                refundMaxPenaltyAmount: number | null
                revalidationAllowed: boolean | null
                revalidationMaxPenaltyAmount: number | null
                isoTicketValidityDate: string | null
                taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
              } | null
              afterDeparture: {
                changeAllowed: boolean | null
                changeMaxPenaltyAmount: number | null
                refundAllowed: boolean | null
                refundMaxPenaltyAmount: number | null
                revalidationAllowed: boolean | null
                revalidationMaxPenaltyAmount: number | null
                isoTicketValidityDate: string | null
                taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
              } | null
              afterDepartureNoShow: {
                changeAllowed: boolean | null
                changeMaxPenaltyAmount: number | null
                refundAllowed: boolean | null
                refundMaxPenaltyAmount: number | null
                revalidationAllowed: boolean | null
                revalidationMaxPenaltyAmount: number | null
                isoTicketValidityDate: string | null
                taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
              } | null
            } | null
            taxDetails: Array<{
              amount: number
              code: string | null
              isNonRefundable: boolean | null
            }> | null
          }
          finalOffer: {
            amount: number
            currency: Types.Currency
            exchangeRate: { ratesToUsd: unknown } | null
          } | null
          seaman: {
            id: string
            firstName: string
            lastName: string
            nationalityIso: string
            rank: number | null
            birthday: string | null
            placeOfBirth: string | null
            firstVaccinationDate: string | null
            firstVaccinationBrand: string | null
            secondVaccinationDate: string | null
            secondVaccinationBrand: string | null
            updatedAt: string
          }
          ticketLineData: { documentNumber: string } | null
          splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
        }>
        seamanBooks: Array<{
          id: string
          bookNumber: string
          issueAt: string | null
          expiryAt: string | null
          isFlagStateSeamanBook: boolean
          nationalityAlpha2: string | null
        }>
        seamanPassports: Array<{
          id: string
          passportNumber: string
          passportIssuePlace: string | null
          passportNationalityIso: string
          passportIssueAt: string
          passportExpiryAt: string
        }>
        seamanVisas: Array<{
          id: string
          visaNumber: string
          visaName: string
          issueAt: string
          expiryAt: string
        }>
        seamanContracts: Array<{
          id: string
          hanseaticAssignmentId: number
          contractFrom: string
          contractUntil: string
        }>
        seamanRank: { name: string; sortOrder: number | null } | null
        seamanCommunications: Array<{ id: string; type: string; value: string | null }>
      }
    }>
    portAgents: Array<{
      portAgent: {
        id: string
        email: string
        firstName: string | null
        lastName: string | null
        role: Types.UserRole
        phoneNumber: string | null
        city: string | null
        countryAlpha2: string | null
        timezoneOlson: string | null
        emailSignature: string | null
        fernandHash: string | null
      }
    }>
    crewChangeComments: Array<{
      id: string
      comment: string
      createdAt: string
      createdBy: {
        id: string
        email: string
        firstName: string | null
        lastName: string | null
        role: Types.UserRole
        phoneNumber: string | null
        city: string | null
        countryAlpha2: string | null
        timezoneOlson: string | null
        emailSignature: string | null
        fernandHash: string | null
      }
    }>
    crewChangeCovidRestrictions: Array<{
      id: string
      version: string
      data: unknown
      createdBy: {
        id: string
        email: string
        firstName: string | null
        lastName: string | null
        role: Types.UserRole
        phoneNumber: string | null
        city: string | null
        countryAlpha2: string | null
        timezoneOlson: string | null
        emailSignature: string | null
        fernandHash: string | null
      }
    }>
  }
}

export type CrewChangeSeamanUpdatedSubscriptionVariables = Types.Exact<{
  bucketId: Types.Scalars['UUID']['input']
  seamenIds: Array<Types.Scalars['UUID']['input']> | Types.Scalars['UUID']['input']
}>

export type CrewChangeSeamanUpdatedSubscription = {
  crewChangeSeamenUpdated: {
    id: string
    firstName: string
    lastName: string
    nationalityIso: string
    rank: number | null
    birthday: string | null
    placeOfBirth: string | null
    firstVaccinationDate: string | null
    firstVaccinationBrand: string | null
    secondVaccinationDate: string | null
    secondVaccinationBrand: string | null
    updatedAt: string
    preferredAirport: {
      isHomeAirport: boolean
      airport: {
        id: string
        alternativeIatas: Array<string>
        city: string
        countryAlpha2: string
        iata: string
        name: string
        timezoneOlson: string | null
        type: Types.AirportType | null
      }
    } | null
    manningAgency: { id: string } | null
    flightBookings: Array<{
      id: string
      active: boolean
      monitored: boolean
      ticketLine: string | null
      comment: string | null
      pnr: string | null
      status: Types.FlightBookingStatus
      amadeusSessionId: string | null
      managedByTravelTeam: boolean
      isAmadeus: boolean
      lastTicketingTime: string | null
      lastTicketingTimeSource: Types.LastTicketingTimeSource
      pendingUpdatedByTravelAgentNotification: boolean
      switchForIdenticalCheaperOfferAutomatically: boolean
      switchForSimilarCheaperOfferAutomatically: boolean
      ticketedAt: string | null
      bucketId: string | null
      autoTicket: boolean
      travelOfficeConfigurationId: string | null
      flight: {
        id: string
        updatedAt: string
        createdAt: string
        flightHash: string
        majorityCarrier: string | null
        travelOfficeConfigurationId: string
        validatingCarrier: string | null
        segments: Array<{
          id: string
          flightNumber: number
          index: number
          departure: {
            timezoneOlson: string | null
            time: string
            name: string | null
            iata: string
            countryAlpha2: string | null
            city: string | null
            date: string
            terminal: string | null
          }
          arrival: {
            timezoneOlson: string | null
            time: string
            name: string | null
            iata: string
            countryAlpha2: string | null
            city: string | null
            date: string
            terminal: string | null
          }
          marketingCarrier: {
            id: string
            createdAt: string
            updatedAt: string
            iata: string
            name: string
            logo: string | null
          } | null
          operatingCarrier: {
            id: string
            createdAt: string
            updatedAt: string
            iata: string
            name: string
            logo: string | null
          } | null
        }>
        travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
      }
      offer: {
        id: string
        amount: number
        tax: number | null
        passengerType: Types.PassengerType | null
        currency: string
        createdAt: string
        updatedAt: string
        flightId: string
        amadeusSessionId: string | null
        segmentFares: Array<{
          availableSeats: number | null
          fareTypes: Array<Types.FareType>
          fareBasisCode: string
          flightSegmentId: string
          bookingClass: string | null
          baggageDetails: {
            freeBagAllowance: number | null
            freeBagAllowanceType: Types.FreeBagAllowanceType | null
          }
        }>
        rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
        minirules: {
          beforeDeparture: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          beforeDepartureNoShow: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          afterDeparture: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          afterDepartureNoShow: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
        } | null
        taxDetails: Array<{
          amount: number
          code: string | null
          isNonRefundable: boolean | null
        }> | null
      }
      finalOffer: {
        amount: number
        currency: Types.Currency
        exchangeRate: { ratesToUsd: unknown } | null
      } | null
      seaman: {
        id: string
        firstName: string
        lastName: string
        nationalityIso: string
        rank: number | null
        birthday: string | null
        placeOfBirth: string | null
        firstVaccinationDate: string | null
        firstVaccinationBrand: string | null
        secondVaccinationDate: string | null
        secondVaccinationBrand: string | null
        updatedAt: string
      }
      ticketLineData: { documentNumber: string } | null
      splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
    }>
    seamanBooks: Array<{
      id: string
      bookNumber: string
      issueAt: string | null
      expiryAt: string | null
      isFlagStateSeamanBook: boolean
      nationalityAlpha2: string | null
    }>
    seamanPassports: Array<{
      id: string
      passportNumber: string
      passportIssuePlace: string | null
      passportNationalityIso: string
      passportIssueAt: string
      passportExpiryAt: string
    }>
    seamanVisas: Array<{
      id: string
      visaNumber: string
      visaName: string
      issueAt: string
      expiryAt: string
    }>
    seamanContracts: Array<{
      id: string
      hanseaticAssignmentId: number
      contractFrom: string
      contractUntil: string
    }>
    seamanRank: { name: string; sortOrder: number | null } | null
    seamanCommunications: Array<{ id: string; type: string; value: string | null }>
  }
}

export type NotifyFlightsBookedMutationVariables = Types.Exact<{
  bucketId: Types.Scalars['UUID']['input']
}>

export type NotifyFlightsBookedMutation = { crewChange: { notifyFlightsBooked: void | null } }

export type UpdateCrewChangeStatusMutationVariables = Types.Exact<{
  bucketId: Types.Scalars['UUID']['input']
  completed?: Types.InputMaybe<Types.Scalars['Boolean']['input']>
  started?: Types.InputMaybe<Types.Scalars['Boolean']['input']>
  archived?: Types.InputMaybe<Types.Scalars['Boolean']['input']>
}>

export type UpdateCrewChangeStatusMutation = { crewChange: { updateStatus: void | null } }

export type StartCrewChangeMutationVariables = Types.Exact<{
  bucketId: Types.Scalars['UUID']['input']
  params: Types.StartCrewChangeParams
}>

export type StartCrewChangeMutation = { crewChange: { start: void | null } }

export type SyncCrewChangeSeamenMutationVariables = Types.Exact<{
  bucketId: Types.Scalars['UUID']['input']
}>

export type SyncCrewChangeSeamenMutation = { crewChange: { syncSeamenFromSource: void | null } }

export type ResetCrewChangeMutationVariables = Types.Exact<{
  bucketId: Types.Scalars['UUID']['input']
}>

export type ResetCrewChangeMutation = { crewChange: { reset: void | null } }

export type CancelCrewChangeMutationVariables = Types.Exact<{
  bucketId: Types.Scalars['UUID']['input']
}>

export type CancelCrewChangeMutation = { crewChange: { cancelCrewChange: void | null } }

export type RestoreCrewChangeMutationVariables = Types.Exact<{
  bucketId: Types.Scalars['UUID']['input']
}>

export type RestoreCrewChangeMutation = {
  crewChange: {
    restoreCrewChange: {
      success: boolean
      error: string | null
      restoredCrewChange: string | null
      updatedSeamen: Array<string> | null
      notUpdatedSeamen: Array<string> | null
      conflictingCrewChanges: Array<{
        activeCrewOperatorType: Types.ActiveCrewOperatorType
        activeCrewSupervisorType: Types.ActiveCrewSupervisorType
        backupCrewOperatorId: string | null
        backupCrewSupervisorId: string | null
        primaryCrewSupervisorName: string | null
        backupCrewSupervisorName: string | null
        bucketId: string
        completed: boolean
        archived: boolean
        ETA: string
        ETB: string
        ETD: string
        vesselScheduleSource: Types.VesselScheduleSource
        manningAgencies: Array<string>
        numOffsigners: number
        numOnsigners: number
        totalNumSeafarersIncludingPromotions: number
        portAgents: Array<string>
        portName: string | null
        portTimezoneOlson: string | null
        primaryCrewOperatorId: string | null
        primaryCrewSupervisorId: string | null
        started: boolean | null
        vesselId: string
        vesselName: string
        isScheduleCancelled: boolean
        flightBookingsLTD: string | null
        bookedFlightBookings: number
        ticketedFlightBookings: number
        openFlightBookings: number
        primaryCrewOperatorName: string | null
        backupCrewOperatorName: string | null
      }> | null
    }
  }
}

export type LoadChangesWithSameCrewQueryVariables = Types.Exact<{
  bucketId: Types.Scalars['UUID']['input']
}>

export type LoadChangesWithSameCrewQuery = {
  crewChangesWithSameCrew: Array<{
    activeCrewOperatorType: Types.ActiveCrewOperatorType
    activeCrewSupervisorType: Types.ActiveCrewSupervisorType
    backupCrewOperatorId: string | null
    backupCrewSupervisorId: string | null
    primaryCrewSupervisorName: string | null
    backupCrewSupervisorName: string | null
    bucketId: string
    completed: boolean
    archived: boolean
    ETA: string
    ETB: string
    ETD: string
    vesselScheduleSource: Types.VesselScheduleSource
    manningAgencies: Array<string>
    numOffsigners: number
    numOnsigners: number
    totalNumSeafarersIncludingPromotions: number
    portAgents: Array<string>
    portName: string | null
    portTimezoneOlson: string | null
    primaryCrewOperatorId: string | null
    primaryCrewSupervisorId: string | null
    started: boolean | null
    vesselId: string
    vesselName: string
    isScheduleCancelled: boolean
    flightBookingsLTD: string | null
    bookedFlightBookings: number
    ticketedFlightBookings: number
    openFlightBookings: number
    primaryCrewOperatorName: string | null
    backupCrewOperatorName: string | null
  }>
}

export const CrewChangeOverviewFieldsFragmentDoc = gql`
  fragment CrewChangeOverviewFields on CrewChangeOverview {
    activeCrewOperatorType
    activeCrewSupervisorType
    backupCrewOperatorId
    backupCrewSupervisorId
    primaryCrewSupervisorName
    backupCrewSupervisorName
    bucketId
    completed
    archived
    ETA
    ETB
    ETD
    vesselScheduleSource
    manningAgencies
    numOffsigners
    numOnsigners
    totalNumSeafarersIncludingPromotions
    portAgents
    portName
    portTimezoneOlson
    primaryCrewOperatorId
    primaryCrewSupervisorId
    started
    vesselId
    vesselName
    isScheduleCancelled
    flightBookingsLTD
    bookedFlightBookings
    ticketedFlightBookings
    openFlightBookings
    primaryCrewOperatorName
    backupCrewOperatorName
  }
`
export const LoadCrewChangesDocument = gql`
  query LoadCrewChanges(
    $completed: Boolean
    $cancelled: Boolean
    $archived: Boolean
    $endAt: DateTime!
    $startAt: DateTime!
    $etd: DateTime!
  ) {
    crewChanges(
      completed: $completed
      cancelled: $cancelled
      archived: $archived
      endAt: $endAt
      startAt: $startAt
      etd: $etd
    ) {
      ...CrewChangeOverviewFields
    }
  }
  ${CrewChangeOverviewFieldsFragmentDoc}
`

/**
 * __useLoadCrewChangesQuery__
 *
 * To run a query within a React component, call `useLoadCrewChangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadCrewChangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadCrewChangesQuery({
 *   variables: {
 *      completed: // value for 'completed'
 *      cancelled: // value for 'cancelled'
 *      archived: // value for 'archived'
 *      endAt: // value for 'endAt'
 *      startAt: // value for 'startAt'
 *      etd: // value for 'etd'
 *   },
 * });
 */
export function useLoadCrewChangesQuery(
  baseOptions: Apollo.QueryHookOptions<LoadCrewChangesQuery, LoadCrewChangesQueryVariables> &
    ({ variables: LoadCrewChangesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LoadCrewChangesQuery, LoadCrewChangesQueryVariables>(
    LoadCrewChangesDocument,
    options
  )
}
export function useLoadCrewChangesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LoadCrewChangesQuery, LoadCrewChangesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LoadCrewChangesQuery, LoadCrewChangesQueryVariables>(
    LoadCrewChangesDocument,
    options
  )
}
export function useLoadCrewChangesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<LoadCrewChangesQuery, LoadCrewChangesQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<LoadCrewChangesQuery, LoadCrewChangesQueryVariables>(
    LoadCrewChangesDocument,
    options
  )
}
export type LoadCrewChangesQueryHookResult = ReturnType<typeof useLoadCrewChangesQuery>
export type LoadCrewChangesLazyQueryHookResult = ReturnType<typeof useLoadCrewChangesLazyQuery>
export type LoadCrewChangesSuspenseQueryHookResult = ReturnType<
  typeof useLoadCrewChangesSuspenseQuery
>
export type LoadCrewChangesQueryResult = Apollo.QueryResult<
  LoadCrewChangesQuery,
  LoadCrewChangesQueryVariables
>
export const AddCrewListEntryCommentDocument = gql`
  mutation AddCrewListEntryComment($id: UUID!, $comment: String!) {
    crewListEntry(id: $id) {
      addComment(comment: $comment) {
        id
        comments {
          id
          comment
        }
      }
    }
  }
`
export type AddCrewListEntryCommentMutationFn = Apollo.MutationFunction<
  AddCrewListEntryCommentMutation,
  AddCrewListEntryCommentMutationVariables
>

/**
 * __useAddCrewListEntryCommentMutation__
 *
 * To run a mutation, you first call `useAddCrewListEntryCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCrewListEntryCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCrewListEntryCommentMutation, { data, loading, error }] = useAddCrewListEntryCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useAddCrewListEntryCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCrewListEntryCommentMutation,
    AddCrewListEntryCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddCrewListEntryCommentMutation,
    AddCrewListEntryCommentMutationVariables
  >(AddCrewListEntryCommentDocument, options)
}
export type AddCrewListEntryCommentMutationHookResult = ReturnType<
  typeof useAddCrewListEntryCommentMutation
>
export type AddCrewListEntryCommentMutationResult =
  Apollo.MutationResult<AddCrewListEntryCommentMutation>
export type AddCrewListEntryCommentMutationOptions = Apollo.BaseMutationOptions<
  AddCrewListEntryCommentMutation,
  AddCrewListEntryCommentMutationVariables
>
export const AddCrewChangeVisaRequirementsDocument = gql`
  mutation AddCrewChangeVisaRequirements(
    $id: UUID!
    $requirements: [AddCrewChangeVisaRequirementsInput!]!
  ) {
    crewListEntry(id: $id) {
      addVisaRequirements(requirements: $requirements) {
        id
      }
    }
  }
`
export type AddCrewChangeVisaRequirementsMutationFn = Apollo.MutationFunction<
  AddCrewChangeVisaRequirementsMutation,
  AddCrewChangeVisaRequirementsMutationVariables
>

/**
 * __useAddCrewChangeVisaRequirementsMutation__
 *
 * To run a mutation, you first call `useAddCrewChangeVisaRequirementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCrewChangeVisaRequirementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCrewChangeVisaRequirementsMutation, { data, loading, error }] = useAddCrewChangeVisaRequirementsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      requirements: // value for 'requirements'
 *   },
 * });
 */
export function useAddCrewChangeVisaRequirementsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCrewChangeVisaRequirementsMutation,
    AddCrewChangeVisaRequirementsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddCrewChangeVisaRequirementsMutation,
    AddCrewChangeVisaRequirementsMutationVariables
  >(AddCrewChangeVisaRequirementsDocument, options)
}
export type AddCrewChangeVisaRequirementsMutationHookResult = ReturnType<
  typeof useAddCrewChangeVisaRequirementsMutation
>
export type AddCrewChangeVisaRequirementsMutationResult =
  Apollo.MutationResult<AddCrewChangeVisaRequirementsMutation>
export type AddCrewChangeVisaRequirementsMutationOptions = Apollo.BaseMutationOptions<
  AddCrewChangeVisaRequirementsMutation,
  AddCrewChangeVisaRequirementsMutationVariables
>
export const AddCrewChangeCovidRestrictionDocument = gql`
  mutation AddCrewChangeCovidRestriction(
    $id: UUID!
    $restriction: CrewChangeCovidRestrictionInput!
  ) {
    crewListEntry(id: $id) {
      addCovidRectriction(restriction: $restriction) {
        id
      }
    }
  }
`
export type AddCrewChangeCovidRestrictionMutationFn = Apollo.MutationFunction<
  AddCrewChangeCovidRestrictionMutation,
  AddCrewChangeCovidRestrictionMutationVariables
>

/**
 * __useAddCrewChangeCovidRestrictionMutation__
 *
 * To run a mutation, you first call `useAddCrewChangeCovidRestrictionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCrewChangeCovidRestrictionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCrewChangeCovidRestrictionMutation, { data, loading, error }] = useAddCrewChangeCovidRestrictionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      restriction: // value for 'restriction'
 *   },
 * });
 */
export function useAddCrewChangeCovidRestrictionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCrewChangeCovidRestrictionMutation,
    AddCrewChangeCovidRestrictionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddCrewChangeCovidRestrictionMutation,
    AddCrewChangeCovidRestrictionMutationVariables
  >(AddCrewChangeCovidRestrictionDocument, options)
}
export type AddCrewChangeCovidRestrictionMutationHookResult = ReturnType<
  typeof useAddCrewChangeCovidRestrictionMutation
>
export type AddCrewChangeCovidRestrictionMutationResult =
  Apollo.MutationResult<AddCrewChangeCovidRestrictionMutation>
export type AddCrewChangeCovidRestrictionMutationOptions = Apollo.BaseMutationOptions<
  AddCrewChangeCovidRestrictionMutation,
  AddCrewChangeCovidRestrictionMutationVariables
>
export const GetCrewChangesLastDataSyncDocument = gql`
  query GetCrewChangesLastDataSync {
    crewChangesLastDataSync {
      createdAt
      type
    }
  }
`

/**
 * __useGetCrewChangesLastDataSyncQuery__
 *
 * To run a query within a React component, call `useGetCrewChangesLastDataSyncQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCrewChangesLastDataSyncQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCrewChangesLastDataSyncQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCrewChangesLastDataSyncQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCrewChangesLastDataSyncQuery,
    GetCrewChangesLastDataSyncQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCrewChangesLastDataSyncQuery, GetCrewChangesLastDataSyncQueryVariables>(
    GetCrewChangesLastDataSyncDocument,
    options
  )
}
export function useGetCrewChangesLastDataSyncLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCrewChangesLastDataSyncQuery,
    GetCrewChangesLastDataSyncQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCrewChangesLastDataSyncQuery,
    GetCrewChangesLastDataSyncQueryVariables
  >(GetCrewChangesLastDataSyncDocument, options)
}
export function useGetCrewChangesLastDataSyncSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCrewChangesLastDataSyncQuery,
        GetCrewChangesLastDataSyncQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetCrewChangesLastDataSyncQuery,
    GetCrewChangesLastDataSyncQueryVariables
  >(GetCrewChangesLastDataSyncDocument, options)
}
export type GetCrewChangesLastDataSyncQueryHookResult = ReturnType<
  typeof useGetCrewChangesLastDataSyncQuery
>
export type GetCrewChangesLastDataSyncLazyQueryHookResult = ReturnType<
  typeof useGetCrewChangesLastDataSyncLazyQuery
>
export type GetCrewChangesLastDataSyncSuspenseQueryHookResult = ReturnType<
  typeof useGetCrewChangesLastDataSyncSuspenseQuery
>
export type GetCrewChangesLastDataSyncQueryResult = Apollo.QueryResult<
  GetCrewChangesLastDataSyncQuery,
  GetCrewChangesLastDataSyncQueryVariables
>
export const GetCrewChangeStartEmailDocument = gql`
  query GetCrewChangeStartEmail($bucketId: UUID!, $params: StartCrewChangeEmailParams) {
    crewChangeInfo(bucketId: $bucketId) {
      startEmail(params: $params) {
        seamanBooksAvailabilityStatus
        passportsAvailabilityStatus
        visasAvailabilityStatus
        email
      }
    }
  }
`

/**
 * __useGetCrewChangeStartEmailQuery__
 *
 * To run a query within a React component, call `useGetCrewChangeStartEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCrewChangeStartEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCrewChangeStartEmailQuery({
 *   variables: {
 *      bucketId: // value for 'bucketId'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetCrewChangeStartEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCrewChangeStartEmailQuery,
    GetCrewChangeStartEmailQueryVariables
  > &
    ({ variables: GetCrewChangeStartEmailQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCrewChangeStartEmailQuery, GetCrewChangeStartEmailQueryVariables>(
    GetCrewChangeStartEmailDocument,
    options
  )
}
export function useGetCrewChangeStartEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCrewChangeStartEmailQuery,
    GetCrewChangeStartEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCrewChangeStartEmailQuery, GetCrewChangeStartEmailQueryVariables>(
    GetCrewChangeStartEmailDocument,
    options
  )
}
export function useGetCrewChangeStartEmailSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCrewChangeStartEmailQuery,
        GetCrewChangeStartEmailQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetCrewChangeStartEmailQuery,
    GetCrewChangeStartEmailQueryVariables
  >(GetCrewChangeStartEmailDocument, options)
}
export type GetCrewChangeStartEmailQueryHookResult = ReturnType<
  typeof useGetCrewChangeStartEmailQuery
>
export type GetCrewChangeStartEmailLazyQueryHookResult = ReturnType<
  typeof useGetCrewChangeStartEmailLazyQuery
>
export type GetCrewChangeStartEmailSuspenseQueryHookResult = ReturnType<
  typeof useGetCrewChangeStartEmailSuspenseQuery
>
export type GetCrewChangeStartEmailQueryResult = Apollo.QueryResult<
  GetCrewChangeStartEmailQuery,
  GetCrewChangeStartEmailQueryVariables
>
export const LoadCrewChangeDocument = gql`
  query LoadCrewChange($bucketId: UUID!, $startAt: DateTime, $endAt: DateTime) {
    crewChange(bucketId: $bucketId, endAt: $endAt, startAt: $startAt) {
      ...CompleteCrewChange
    }
  }
  ${CompleteCrewChangeFragmentDoc}
`

/**
 * __useLoadCrewChangeQuery__
 *
 * To run a query within a React component, call `useLoadCrewChangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadCrewChangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadCrewChangeQuery({
 *   variables: {
 *      bucketId: // value for 'bucketId'
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *   },
 * });
 */
export function useLoadCrewChangeQuery(
  baseOptions: Apollo.QueryHookOptions<LoadCrewChangeQuery, LoadCrewChangeQueryVariables> &
    ({ variables: LoadCrewChangeQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LoadCrewChangeQuery, LoadCrewChangeQueryVariables>(
    LoadCrewChangeDocument,
    options
  )
}
export function useLoadCrewChangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LoadCrewChangeQuery, LoadCrewChangeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LoadCrewChangeQuery, LoadCrewChangeQueryVariables>(
    LoadCrewChangeDocument,
    options
  )
}
export function useLoadCrewChangeSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<LoadCrewChangeQuery, LoadCrewChangeQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<LoadCrewChangeQuery, LoadCrewChangeQueryVariables>(
    LoadCrewChangeDocument,
    options
  )
}
export type LoadCrewChangeQueryHookResult = ReturnType<typeof useLoadCrewChangeQuery>
export type LoadCrewChangeLazyQueryHookResult = ReturnType<typeof useLoadCrewChangeLazyQuery>
export type LoadCrewChangeSuspenseQueryHookResult = ReturnType<
  typeof useLoadCrewChangeSuspenseQuery
>
export type LoadCrewChangeQueryResult = Apollo.QueryResult<
  LoadCrewChangeQuery,
  LoadCrewChangeQueryVariables
>
export const CrewChangeSeamanUpdatedDocument = gql`
  subscription CrewChangeSeamanUpdated($bucketId: UUID!, $seamenIds: [UUID!]!) {
    crewChangeSeamenUpdated(seamenIds: $seamenIds, bucketId: $bucketId) {
      ...CompleteSeamanDetails
    }
  }
  ${CompleteSeamanDetailsFragmentDoc}
`

/**
 * __useCrewChangeSeamanUpdatedSubscription__
 *
 * To run a query within a React component, call `useCrewChangeSeamanUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCrewChangeSeamanUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrewChangeSeamanUpdatedSubscription({
 *   variables: {
 *      bucketId: // value for 'bucketId'
 *      seamenIds: // value for 'seamenIds'
 *   },
 * });
 */
export function useCrewChangeSeamanUpdatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    CrewChangeSeamanUpdatedSubscription,
    CrewChangeSeamanUpdatedSubscriptionVariables
  > &
    (
      | { variables: CrewChangeSeamanUpdatedSubscriptionVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    CrewChangeSeamanUpdatedSubscription,
    CrewChangeSeamanUpdatedSubscriptionVariables
  >(CrewChangeSeamanUpdatedDocument, options)
}
export type CrewChangeSeamanUpdatedSubscriptionHookResult = ReturnType<
  typeof useCrewChangeSeamanUpdatedSubscription
>
export type CrewChangeSeamanUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<CrewChangeSeamanUpdatedSubscription>
export const NotifyFlightsBookedDocument = gql`
  mutation NotifyFlightsBooked($bucketId: UUID!) {
    crewChange(bucketId: $bucketId) {
      notifyFlightsBooked
    }
  }
`
export type NotifyFlightsBookedMutationFn = Apollo.MutationFunction<
  NotifyFlightsBookedMutation,
  NotifyFlightsBookedMutationVariables
>

/**
 * __useNotifyFlightsBookedMutation__
 *
 * To run a mutation, you first call `useNotifyFlightsBookedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotifyFlightsBookedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notifyFlightsBookedMutation, { data, loading, error }] = useNotifyFlightsBookedMutation({
 *   variables: {
 *      bucketId: // value for 'bucketId'
 *   },
 * });
 */
export function useNotifyFlightsBookedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NotifyFlightsBookedMutation,
    NotifyFlightsBookedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<NotifyFlightsBookedMutation, NotifyFlightsBookedMutationVariables>(
    NotifyFlightsBookedDocument,
    options
  )
}
export type NotifyFlightsBookedMutationHookResult = ReturnType<
  typeof useNotifyFlightsBookedMutation
>
export type NotifyFlightsBookedMutationResult = Apollo.MutationResult<NotifyFlightsBookedMutation>
export type NotifyFlightsBookedMutationOptions = Apollo.BaseMutationOptions<
  NotifyFlightsBookedMutation,
  NotifyFlightsBookedMutationVariables
>
export const UpdateCrewChangeStatusDocument = gql`
  mutation UpdateCrewChangeStatus(
    $bucketId: UUID!
    $completed: Boolean
    $started: Boolean
    $archived: Boolean
  ) {
    crewChange(bucketId: $bucketId) {
      updateStatus(completed: $completed, started: $started, archived: $archived)
    }
  }
`
export type UpdateCrewChangeStatusMutationFn = Apollo.MutationFunction<
  UpdateCrewChangeStatusMutation,
  UpdateCrewChangeStatusMutationVariables
>

/**
 * __useUpdateCrewChangeStatusMutation__
 *
 * To run a mutation, you first call `useUpdateCrewChangeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCrewChangeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCrewChangeStatusMutation, { data, loading, error }] = useUpdateCrewChangeStatusMutation({
 *   variables: {
 *      bucketId: // value for 'bucketId'
 *      completed: // value for 'completed'
 *      started: // value for 'started'
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function useUpdateCrewChangeStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCrewChangeStatusMutation,
    UpdateCrewChangeStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCrewChangeStatusMutation,
    UpdateCrewChangeStatusMutationVariables
  >(UpdateCrewChangeStatusDocument, options)
}
export type UpdateCrewChangeStatusMutationHookResult = ReturnType<
  typeof useUpdateCrewChangeStatusMutation
>
export type UpdateCrewChangeStatusMutationResult =
  Apollo.MutationResult<UpdateCrewChangeStatusMutation>
export type UpdateCrewChangeStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateCrewChangeStatusMutation,
  UpdateCrewChangeStatusMutationVariables
>
export const StartCrewChangeDocument = gql`
  mutation StartCrewChange($bucketId: UUID!, $params: StartCrewChangeParams!) {
    crewChange(bucketId: $bucketId) {
      start(params: $params)
    }
  }
`
export type StartCrewChangeMutationFn = Apollo.MutationFunction<
  StartCrewChangeMutation,
  StartCrewChangeMutationVariables
>

/**
 * __useStartCrewChangeMutation__
 *
 * To run a mutation, you first call `useStartCrewChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartCrewChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startCrewChangeMutation, { data, loading, error }] = useStartCrewChangeMutation({
 *   variables: {
 *      bucketId: // value for 'bucketId'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useStartCrewChangeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartCrewChangeMutation,
    StartCrewChangeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<StartCrewChangeMutation, StartCrewChangeMutationVariables>(
    StartCrewChangeDocument,
    options
  )
}
export type StartCrewChangeMutationHookResult = ReturnType<typeof useStartCrewChangeMutation>
export type StartCrewChangeMutationResult = Apollo.MutationResult<StartCrewChangeMutation>
export type StartCrewChangeMutationOptions = Apollo.BaseMutationOptions<
  StartCrewChangeMutation,
  StartCrewChangeMutationVariables
>
export const SyncCrewChangeSeamenDocument = gql`
  mutation SyncCrewChangeSeamen($bucketId: UUID!) {
    crewChange(bucketId: $bucketId) {
      syncSeamenFromSource
    }
  }
`
export type SyncCrewChangeSeamenMutationFn = Apollo.MutationFunction<
  SyncCrewChangeSeamenMutation,
  SyncCrewChangeSeamenMutationVariables
>

/**
 * __useSyncCrewChangeSeamenMutation__
 *
 * To run a mutation, you first call `useSyncCrewChangeSeamenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncCrewChangeSeamenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncCrewChangeSeamenMutation, { data, loading, error }] = useSyncCrewChangeSeamenMutation({
 *   variables: {
 *      bucketId: // value for 'bucketId'
 *   },
 * });
 */
export function useSyncCrewChangeSeamenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyncCrewChangeSeamenMutation,
    SyncCrewChangeSeamenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SyncCrewChangeSeamenMutation, SyncCrewChangeSeamenMutationVariables>(
    SyncCrewChangeSeamenDocument,
    options
  )
}
export type SyncCrewChangeSeamenMutationHookResult = ReturnType<
  typeof useSyncCrewChangeSeamenMutation
>
export type SyncCrewChangeSeamenMutationResult = Apollo.MutationResult<SyncCrewChangeSeamenMutation>
export type SyncCrewChangeSeamenMutationOptions = Apollo.BaseMutationOptions<
  SyncCrewChangeSeamenMutation,
  SyncCrewChangeSeamenMutationVariables
>
export const ResetCrewChangeDocument = gql`
  mutation ResetCrewChange($bucketId: UUID!) {
    crewChange(bucketId: $bucketId) {
      reset
    }
  }
`
export type ResetCrewChangeMutationFn = Apollo.MutationFunction<
  ResetCrewChangeMutation,
  ResetCrewChangeMutationVariables
>

/**
 * __useResetCrewChangeMutation__
 *
 * To run a mutation, you first call `useResetCrewChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetCrewChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetCrewChangeMutation, { data, loading, error }] = useResetCrewChangeMutation({
 *   variables: {
 *      bucketId: // value for 'bucketId'
 *   },
 * });
 */
export function useResetCrewChangeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetCrewChangeMutation,
    ResetCrewChangeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ResetCrewChangeMutation, ResetCrewChangeMutationVariables>(
    ResetCrewChangeDocument,
    options
  )
}
export type ResetCrewChangeMutationHookResult = ReturnType<typeof useResetCrewChangeMutation>
export type ResetCrewChangeMutationResult = Apollo.MutationResult<ResetCrewChangeMutation>
export type ResetCrewChangeMutationOptions = Apollo.BaseMutationOptions<
  ResetCrewChangeMutation,
  ResetCrewChangeMutationVariables
>
export const CancelCrewChangeDocument = gql`
  mutation CancelCrewChange($bucketId: UUID!) {
    crewChange(bucketId: $bucketId) {
      cancelCrewChange
    }
  }
`
export type CancelCrewChangeMutationFn = Apollo.MutationFunction<
  CancelCrewChangeMutation,
  CancelCrewChangeMutationVariables
>

/**
 * __useCancelCrewChangeMutation__
 *
 * To run a mutation, you first call `useCancelCrewChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCrewChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCrewChangeMutation, { data, loading, error }] = useCancelCrewChangeMutation({
 *   variables: {
 *      bucketId: // value for 'bucketId'
 *   },
 * });
 */
export function useCancelCrewChangeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelCrewChangeMutation,
    CancelCrewChangeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CancelCrewChangeMutation, CancelCrewChangeMutationVariables>(
    CancelCrewChangeDocument,
    options
  )
}
export type CancelCrewChangeMutationHookResult = ReturnType<typeof useCancelCrewChangeMutation>
export type CancelCrewChangeMutationResult = Apollo.MutationResult<CancelCrewChangeMutation>
export type CancelCrewChangeMutationOptions = Apollo.BaseMutationOptions<
  CancelCrewChangeMutation,
  CancelCrewChangeMutationVariables
>
export const RestoreCrewChangeDocument = gql`
  mutation RestoreCrewChange($bucketId: UUID!) {
    crewChange(bucketId: $bucketId) {
      restoreCrewChange {
        success
        error
        conflictingCrewChanges {
          ...CrewChangeOverviewFields
        }
        restoredCrewChange
        updatedSeamen
        notUpdatedSeamen
      }
    }
  }
  ${CrewChangeOverviewFieldsFragmentDoc}
`
export type RestoreCrewChangeMutationFn = Apollo.MutationFunction<
  RestoreCrewChangeMutation,
  RestoreCrewChangeMutationVariables
>

/**
 * __useRestoreCrewChangeMutation__
 *
 * To run a mutation, you first call `useRestoreCrewChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreCrewChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreCrewChangeMutation, { data, loading, error }] = useRestoreCrewChangeMutation({
 *   variables: {
 *      bucketId: // value for 'bucketId'
 *   },
 * });
 */
export function useRestoreCrewChangeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreCrewChangeMutation,
    RestoreCrewChangeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RestoreCrewChangeMutation, RestoreCrewChangeMutationVariables>(
    RestoreCrewChangeDocument,
    options
  )
}
export type RestoreCrewChangeMutationHookResult = ReturnType<typeof useRestoreCrewChangeMutation>
export type RestoreCrewChangeMutationResult = Apollo.MutationResult<RestoreCrewChangeMutation>
export type RestoreCrewChangeMutationOptions = Apollo.BaseMutationOptions<
  RestoreCrewChangeMutation,
  RestoreCrewChangeMutationVariables
>
export const LoadChangesWithSameCrewDocument = gql`
  query LoadChangesWithSameCrew($bucketId: UUID!) {
    crewChangesWithSameCrew(bucketId: $bucketId) {
      ...CrewChangeOverviewFields
    }
  }
  ${CrewChangeOverviewFieldsFragmentDoc}
`

/**
 * __useLoadChangesWithSameCrewQuery__
 *
 * To run a query within a React component, call `useLoadChangesWithSameCrewQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadChangesWithSameCrewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadChangesWithSameCrewQuery({
 *   variables: {
 *      bucketId: // value for 'bucketId'
 *   },
 * });
 */
export function useLoadChangesWithSameCrewQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadChangesWithSameCrewQuery,
    LoadChangesWithSameCrewQueryVariables
  > &
    ({ variables: LoadChangesWithSameCrewQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LoadChangesWithSameCrewQuery, LoadChangesWithSameCrewQueryVariables>(
    LoadChangesWithSameCrewDocument,
    options
  )
}
export function useLoadChangesWithSameCrewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadChangesWithSameCrewQuery,
    LoadChangesWithSameCrewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LoadChangesWithSameCrewQuery, LoadChangesWithSameCrewQueryVariables>(
    LoadChangesWithSameCrewDocument,
    options
  )
}
export function useLoadChangesWithSameCrewSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadChangesWithSameCrewQuery,
        LoadChangesWithSameCrewQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    LoadChangesWithSameCrewQuery,
    LoadChangesWithSameCrewQueryVariables
  >(LoadChangesWithSameCrewDocument, options)
}
export type LoadChangesWithSameCrewQueryHookResult = ReturnType<
  typeof useLoadChangesWithSameCrewQuery
>
export type LoadChangesWithSameCrewLazyQueryHookResult = ReturnType<
  typeof useLoadChangesWithSameCrewLazyQuery
>
export type LoadChangesWithSameCrewSuspenseQueryHookResult = ReturnType<
  typeof useLoadChangesWithSameCrewSuspenseQuery
>
export type LoadChangesWithSameCrewQueryResult = Apollo.QueryResult<
  LoadChangesWithSameCrewQuery,
  LoadChangesWithSameCrewQueryVariables
>
