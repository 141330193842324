import { Field, Switch } from '@headlessui/react'
import clsx from 'clsx'

interface ToggleSwitchProps {
  onToggle: (toggle: boolean) => void
  toggled: boolean
  label?: string
  labelPlacement?: 'prefix' | 'postfix'
  placement?: 'justify-between' | 'justify-around'
  disabled?: boolean
  color?: 'blue' | 'green'
  size?: 'sm' | 'md' | 'xs'
  id?: string
}

const ToggleSwitch = ({
  id,
  onToggle,
  toggled,
  label,
  placement,
  labelPlacement = 'prefix',
  disabled,
  color = 'blue',
  size = 'md',
}: ToggleSwitchProps) => {
  return (
    <Field id={id}>
      <div className={clsx('flex items-center text-sm', placement)}>
        {label && labelPlacement === 'prefix' && (
          <Switch.Label className={clsx('mr-4', !toggled && 'text-gray-500')}>{label}</Switch.Label>
        )}
        <Switch
          disabled={disabled}
          checked={toggled}
          onChange={onToggle}
          onClick={(ev) => {
            ev.stopPropagation()
          }}
          className={clsx(
            color === 'green'
              ? {
                  'bg-emerald-600': toggled,
                  'focus:ring-emerald-500': true,
                }
              : {
                  'bg-blue-600': toggled,
                  'focus:ring-indigo-500': true,
                },
            !toggled && 'bg-gray-200',
            size === 'sm' && 'h-4 w-6',
            size === 'xs' && 'h-2.5 w-4',
            size === 'md' && 'h-6 w-11',
            'relative inline-flex items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2'
          )}
        >
          <span
            className={clsx(
              size === 'xs' && {
                'right-[1px]': toggled,
                'left-[1px]': !toggled,
                'h-2 w-2': true,
              },
              size === 'sm' && {
                'right-[3px]': toggled,
                'left-[3px]': !toggled,
                'h-2 w-2': true,
              },
              size === 'md' && {
                'right-[4px]': toggled,
                'left-[4px]': !toggled,
                'h-4 w-4': true,
              },
              'inline-block absolute transform rounded-full bg-white transition-transform'
            )}
          />
        </Switch>
        {label && labelPlacement === 'postfix' && (
          <Switch.Label className={clsx('mr-4', !toggled && 'text-gray-500')}>{label}</Switch.Label>
        )}
      </div>
    </Field>
  )
}

export default ToggleSwitch
