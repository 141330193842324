import { sum } from 'lodash'
import { Mobile, TabletAndAbove } from 'utils/getResponsiveLayout'
import WarningIcon from '~components/ui/Icon/WarningIcon'
import Table from '~components/ui/Table/Table'
import TableBody from '~components/ui/Table/TableBody'
import { CrewChangeOverViewTab } from '~pages/index'
import CrewChangeTableHead from './CrewChangeTableHead'
import CrewChangesOverviewCard from './CrewChangesOverviewCard'
import CrewChangesTableRow from './CrewChangesTableRow'
import { CrewChangeSummary } from './types'

interface CancelledCrewChangeTableProps {
  crewChanges: CrewChangeSummary[]
}

export default function CancelledCrewChangesTable({ crewChanges }: CancelledCrewChangeTableProps) {
  const crewChangesWithActiveFlights = crewChanges.filter(
    (v) => sum([v.ticketedFlightBookings, v.bookedFlightBookings]) > 0
  )
  const otherCrewChanges = crewChanges.filter(
    (v) => !crewChangesWithActiveFlights.map((t) => t.bucketId).includes(v.bucketId)
  )

  const renderCrewChangeList = (crewChangesParam: CrewChangeSummary[], title: string) => (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-2">
        {title === 'Active flights' && <WarningIcon className="w-4 h-4 text-red-500" />}
        <span
          className={`flex space-x-1 text-md font-semibold leading-none ${
            title === 'Active flights' ? 'text-red-500' : ''
          }`}
        >
          {title}
        </span>
      </div>
      <TabletAndAbove>
        <Table className="overflow-visible">
          <CrewChangeTableHead />
          <TableBody>
            {crewChangesParam.map((crewChange) => (
              <CrewChangesTableRow
                crewChange={crewChange}
                key={crewChange.bucketId}
                activeTab={CrewChangeOverViewTab.Cancelled}
              />
            ))}
          </TableBody>
        </Table>
      </TabletAndAbove>
      <Mobile>
        <div className="grid grid-cols-1 gap-2">
          {crewChangesParam.map((crewChange) => (
            <CrewChangesOverviewCard key={crewChange.bucketId} crewChange={crewChange} />
          ))}
        </div>
      </Mobile>
    </div>
  )

  return (
    <div className="flex flex-col gap-6">
      {crewChangesWithActiveFlights.length > 0 &&
        renderCrewChangeList(crewChangesWithActiveFlights, 'Active flights')}

      {otherCrewChanges.length > 0 && renderCrewChangeList(otherCrewChanges, 'Other crew changes')}
    </div>
  )
}
