import { useContext } from 'react'
import { Role } from 'types'
import { DataSourceSystem } from '~api/types.generated'
import { UserContext } from '~context/UserContext'

export default function useIsAllowedCrewChangeManagement() {
  const { user } = useContext(UserContext)

  return (
    [Role.TillaBackoffice, Role.CrewManager, Role.CrewOperator, Role.CrewSupervisor].includes(
      user.role
    ) &&
    [
      DataSourceSystem.CrewService,
      DataSourceSystem.Mintra,
      DataSourceSystem.Seacrew,
      DataSourceSystem.Stolt,
      DataSourceSystem.Adonis,
    ].includes(user.tenant.dataSourceSystem)
  )
}
