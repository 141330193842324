import Link from 'next/link'
import { useRouter } from 'next/router'
import rbac, { Action, Resource } from 'rbac'
import { ReactNode, useState } from 'react'
import { canViewDecisionMaking } from 'utils/canViewDecisionMaking'
import { isProd } from 'utils/checkEnv'
import { useMobileMediaQuery } from 'utils/getResponsiveLayout'
import { UserRole } from '~api/types.generated'
import NotificationsBadge from '~components/Notifications/NotificationsBadge'
import EditEmailSignatureModal from '~components/User/EditEmailSignatureModal'
import AppShellActions from '~components/ui/AppShell/AppShellActions'
import AppShellHeader from '~components/ui/AppShell/AppShellHeader'
import AppShellNavigation from '~components/ui/AppShell/AppShellNavigation'
import AppShellNavigationItem from '~components/ui/AppShell/AppShellNavigationItem'
import BetaTag from '~components/ui/BetaTag/BetaTag'
import ButtonIcon from '~components/ui/Button/ButtonIcon'
import Container from '~components/ui/Container/Container'
import PlanCrewChangeIcon from '~components/ui/Icon/PlanCrewChangeIcon'
import PlusIcon from '~components/ui/Icon/PlusIcon'
import useCurrentUser from '~hooks/useCurrentUser'
import useIsAllowedCrewChangeManagement from '~hooks/useIsAllowedCrewChangeManagement'
import useIsBackofficeUser from '~hooks/useIsBackofficeUser'
import { useTrackEvent } from '~hooks/useTrackEvent'
import UserMenu from './UserMenu'

function App({ children, noContainer }: { children: ReactNode; noContainer?: boolean }) {
  const router = useRouter()
  const isMobile = useMobileMediaQuery()
  const [showEditSignatureModal, setShowEditSignatureModal] = useState(false)
  const [isBurgerToggled, setIsBurgerToggled] = useState(false)
  const isStrictActive = (href: string) => {
    return router.asPath === href
  }

  const isActive = (href: string) => {
    return router.asPath.startsWith(href)
  }

  const { user, isLoading: isLoadingUser } = useCurrentUser()
  const isBackofficeUser = useIsBackofficeUser()
  const isAllowedCrewChangeManagement = useIsAllowedCrewChangeManagement()

  const trackEvent = useTrackEvent()

  const deferralMixpanelTracking = () => {
    trackEvent({
      event: 'Clicked on Deferrals Overview',
    })
  }

  if (isLoadingUser) {
    return <></>
  }

  return (
    <div>
      <AppShellHeader isBurgerToggled={isBurgerToggled} setIsBurgerToggled={setIsBurgerToggled}>
        <AppShellNavigation>
          <Link
            href="/"
            className="h-full"
            onClick={() => {
              setIsBurgerToggled(false)
            }}
          >
            <AppShellNavigationItem
              isActive={isStrictActive('/') || isActive('/crew-changes')}
              onClick={() => {
                setIsBurgerToggled(false)
              }}
            >
              Home
            </AppShellNavigationItem>
          </Link>

          {!isMobile && rbac.can(user.role).perform(Action.UseOmniSearch) && (
            <Link
              href="/search"
              className="h-full"
              onClick={() => {
                setIsBurgerToggled(false)
              }}
            >
              <AppShellNavigationItem isActive={isActive('/search')}>Search</AppShellNavigationItem>
            </Link>
          )}

          {rbac.can(user.role).read(Resource.Landing) && (
            <Link
              href="/landing"
              className="h-full"
              onClick={() => {
                setIsBurgerToggled(false)
              }}
            >
              <AppShellNavigationItem isActive={isActive('/landing')}>
                Landing
              </AppShellNavigationItem>
            </Link>
          )}

          {canViewDecisionMaking(user) && (
            <Link
              href="/port-planning"
              className="h-full"
              onClick={() => {
                setIsBurgerToggled(false)
              }}
            >
              <AppShellNavigationItem isActive={isActive('/port-planning')}>
                Decision Making
                <BetaTag />
              </AppShellNavigationItem>
            </Link>
          )}

          {isBackofficeUser && (
            <Link
              href="/travel-agent/flight-deferrals"
              className="h-full"
              onClick={() => {
                setIsBurgerToggled(false)
                deferralMixpanelTracking()
              }}
            >
              <AppShellNavigationItem isActive={isActive('/travel-agent/flight-deferrals')}>
                Deferrals overview
                <BetaTag />
              </AppShellNavigationItem>
            </Link>
          )}
        </AppShellNavigation>

        {isBurgerToggled && <hr />}

        <div className="flex justify-between space-x-4">
          <AppShellActions>
            {isAllowedCrewChangeManagement && (
              <div className="md:space-x-2 lg:space-x-2">
                {!isProd() && (
                  <Link
                    href="/crew-changes/plan-crew-change"
                    onClick={() => {
                      setIsBurgerToggled(false)
                    }}
                  >
                    <ButtonIcon variant="outline" size="small" icon={PlanCrewChangeIcon}>
                      Plan Crew Change
                    </ButtonIcon>
                  </Link>
                )}

                <Link
                  className="h-full"
                  href="/crew-changes/create-crew-change"
                  onClick={() => {
                    setIsBurgerToggled(false)
                  }}
                >
                  <ButtonIcon size="small" icon={PlusIcon}>
                    Create Crew Change
                  </ButtonIcon>
                </Link>
              </div>
            )}
          </AppShellActions>

          <div className=" flex items-center space-x-4">
            {rbac.can(user.role).read(Resource.Notifications) && <NotificationsBadge />}

            {[UserRole.TravelAgent, UserRole.TillaBackoffice].includes(user.role) && (
              <div className="text-xs">
                Client: <span className="text-brand">{user.tenant.name}</span>
              </div>
            )}

            <UserMenu />
          </div>
        </div>
      </AppShellHeader>
      {noContainer ? children : <Container>{children}</Container>}
      {showEditSignatureModal && (
        <EditEmailSignatureModal onClose={() => setShowEditSignatureModal(false)} />
      )}
    </div>
  )
}

export default App
