/* eslint-disable */
import * as Types from './types.generated'

import { gql } from '@apollo/client'
import { SafeUserFieldsFragmentDoc } from './user-gql.generated'
import {
  CompleteSeamanFragmentDoc,
  CompleteFlightBookingFragmentDoc,
  CompleteFlightBookingRefundFragmentDoc,
  VesselFieldsFragmentDoc,
  ParsedFlightOfferFieldsFragmentDoc,
} from './fragments.generated'
import { AirportFieldsFragmentDoc } from './airports-gql.generated'
import { SeaportFieldsFragmentDoc } from './seaports-gql.generated'
export type CompleteCrewChangeSeamanFragment = {
  id: string
  role: Types.CrewChangeRole
  active: boolean
  isSearchDeferred: boolean
  seamanId: string
  confirmationStatuses: Array<{
    id: string
    status: Types.SeamanConfirmationStatus
    user: {
      id: string
      email: string
      firstName: string | null
      lastName: string | null
      role: Types.UserRole
      phoneNumber: string | null
      city: string | null
      countryAlpha2: string | null
      timezoneOlson: string | null
      emailSignature: string | null
      fernandHash: string | null
    }
  }>
  crewChangeVisaRequirements: Array<{
    id: string
    createdAt: string
    isVisaRequired: boolean
    region: string
    visaRequiredOn: Types.VisaOrCovidTestRequiredOn
    additionalComments: string
    createdBy: {
      id: string
      email: string
      firstName: string | null
      lastName: string | null
      role: Types.UserRole
      phoneNumber: string | null
      city: string | null
      countryAlpha2: string | null
      timezoneOlson: string | null
      emailSignature: string | null
      fernandHash: string | null
    }
  }>
  manningAgency: { id: string; name: string } | null
  seaman: {
    id: string
    firstName: string
    lastName: string
    nationalityIso: string
    rank: number | null
    birthday: string | null
    placeOfBirth: string | null
    firstVaccinationDate: string | null
    firstVaccinationBrand: string | null
    secondVaccinationDate: string | null
    secondVaccinationBrand: string | null
    updatedAt: string
    preferredAirport: {
      isHomeAirport: boolean
      airport: {
        id: string
        alternativeIatas: Array<string>
        city: string
        countryAlpha2: string
        iata: string
        name: string
        timezoneOlson: string | null
        type: Types.AirportType | null
      }
    } | null
    manningAgency: { id: string } | null
    flightBookings: Array<{
      id: string
      active: boolean
      monitored: boolean
      ticketLine: string | null
      comment: string | null
      pnr: string | null
      status: Types.FlightBookingStatus
      amadeusSessionId: string | null
      managedByTravelTeam: boolean
      isAmadeus: boolean
      lastTicketingTime: string | null
      lastTicketingTimeSource: Types.LastTicketingTimeSource
      pendingUpdatedByTravelAgentNotification: boolean
      switchForIdenticalCheaperOfferAutomatically: boolean
      switchForSimilarCheaperOfferAutomatically: boolean
      ticketedAt: string | null
      bucketId: string | null
      autoTicket: boolean
      travelOfficeConfigurationId: string | null
      flight: {
        id: string
        updatedAt: string
        createdAt: string
        flightHash: string
        majorityCarrier: string | null
        travelOfficeConfigurationId: string
        validatingCarrier: string | null
        segments: Array<{
          id: string
          flightNumber: number
          index: number
          departure: {
            timezoneOlson: string | null
            time: string
            name: string | null
            iata: string
            countryAlpha2: string | null
            city: string | null
            date: string
            terminal: string | null
          }
          arrival: {
            timezoneOlson: string | null
            time: string
            name: string | null
            iata: string
            countryAlpha2: string | null
            city: string | null
            date: string
            terminal: string | null
          }
          marketingCarrier: {
            id: string
            createdAt: string
            updatedAt: string
            iata: string
            name: string
            logo: string | null
          } | null
          operatingCarrier: {
            id: string
            createdAt: string
            updatedAt: string
            iata: string
            name: string
            logo: string | null
          } | null
        }>
        travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
      }
      offer: {
        id: string
        amount: number
        tax: number | null
        passengerType: Types.PassengerType | null
        currency: string
        createdAt: string
        updatedAt: string
        flightId: string
        amadeusSessionId: string | null
        segmentFares: Array<{
          availableSeats: number | null
          fareTypes: Array<Types.FareType>
          fareBasisCode: string
          flightSegmentId: string
          bookingClass: string | null
          baggageDetails: {
            freeBagAllowance: number | null
            freeBagAllowanceType: Types.FreeBagAllowanceType | null
          }
        }>
        rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
        minirules: {
          beforeDeparture: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          beforeDepartureNoShow: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          afterDeparture: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          afterDepartureNoShow: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
        } | null
        taxDetails: Array<{
          amount: number
          code: string | null
          isNonRefundable: boolean | null
        }> | null
      }
      finalOffer: {
        amount: number
        currency: Types.Currency
        exchangeRate: { ratesToUsd: unknown } | null
      } | null
      seaman: {
        id: string
        firstName: string
        lastName: string
        nationalityIso: string
        rank: number | null
        birthday: string | null
        placeOfBirth: string | null
        firstVaccinationDate: string | null
        firstVaccinationBrand: string | null
        secondVaccinationDate: string | null
        secondVaccinationBrand: string | null
        updatedAt: string
      }
      ticketLineData: { documentNumber: string } | null
      splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
    }>
    seamanBooks: Array<{
      id: string
      bookNumber: string
      issueAt: string | null
      expiryAt: string | null
      isFlagStateSeamanBook: boolean
      nationalityAlpha2: string | null
    }>
    seamanPassports: Array<{
      id: string
      passportNumber: string
      passportIssuePlace: string | null
      passportNationalityIso: string
      passportIssueAt: string
      passportExpiryAt: string
    }>
    seamanVisas: Array<{
      id: string
      visaNumber: string
      visaName: string
      issueAt: string
      expiryAt: string
    }>
    seamanContracts: Array<{
      id: string
      hanseaticAssignmentId: number
      contractFrom: string
      contractUntil: string
    }>
    seamanRank: { name: string; sortOrder: number | null } | null
    seamanCommunications: Array<{ id: string; type: string; value: string | null }>
  }
}

export type CompleteSeamanDetailsFragment = {
  id: string
  firstName: string
  lastName: string
  nationalityIso: string
  rank: number | null
  birthday: string | null
  placeOfBirth: string | null
  firstVaccinationDate: string | null
  firstVaccinationBrand: string | null
  secondVaccinationDate: string | null
  secondVaccinationBrand: string | null
  updatedAt: string
  preferredAirport: {
    isHomeAirport: boolean
    airport: {
      id: string
      alternativeIatas: Array<string>
      city: string
      countryAlpha2: string
      iata: string
      name: string
      timezoneOlson: string | null
      type: Types.AirportType | null
    }
  } | null
  manningAgency: { id: string } | null
  flightBookings: Array<{
    id: string
    active: boolean
    monitored: boolean
    ticketLine: string | null
    comment: string | null
    pnr: string | null
    status: Types.FlightBookingStatus
    amadeusSessionId: string | null
    managedByTravelTeam: boolean
    isAmadeus: boolean
    lastTicketingTime: string | null
    lastTicketingTimeSource: Types.LastTicketingTimeSource
    pendingUpdatedByTravelAgentNotification: boolean
    switchForIdenticalCheaperOfferAutomatically: boolean
    switchForSimilarCheaperOfferAutomatically: boolean
    ticketedAt: string | null
    bucketId: string | null
    autoTicket: boolean
    travelOfficeConfigurationId: string | null
    flight: {
      id: string
      updatedAt: string
      createdAt: string
      flightHash: string
      majorityCarrier: string | null
      travelOfficeConfigurationId: string
      validatingCarrier: string | null
      segments: Array<{
        id: string
        flightNumber: number
        index: number
        departure: {
          timezoneOlson: string | null
          time: string
          name: string | null
          iata: string
          countryAlpha2: string | null
          city: string | null
          date: string
          terminal: string | null
        }
        arrival: {
          timezoneOlson: string | null
          time: string
          name: string | null
          iata: string
          countryAlpha2: string | null
          city: string | null
          date: string
          terminal: string | null
        }
        marketingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
        operatingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
      }>
      travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
    }
    offer: {
      id: string
      amount: number
      tax: number | null
      passengerType: Types.PassengerType | null
      currency: string
      createdAt: string
      updatedAt: string
      flightId: string
      amadeusSessionId: string | null
      segmentFares: Array<{
        availableSeats: number | null
        fareTypes: Array<Types.FareType>
        fareBasisCode: string
        flightSegmentId: string
        bookingClass: string | null
        baggageDetails: {
          freeBagAllowance: number | null
          freeBagAllowanceType: Types.FreeBagAllowanceType | null
        }
      }>
      rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
      minirules: {
        beforeDeparture: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        beforeDepartureNoShow: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        afterDeparture: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        afterDepartureNoShow: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
      } | null
      taxDetails: Array<{
        amount: number
        code: string | null
        isNonRefundable: boolean | null
      }> | null
    }
    finalOffer: {
      amount: number
      currency: Types.Currency
      exchangeRate: { ratesToUsd: unknown } | null
    } | null
    seaman: {
      id: string
      firstName: string
      lastName: string
      nationalityIso: string
      rank: number | null
      birthday: string | null
      placeOfBirth: string | null
      firstVaccinationDate: string | null
      firstVaccinationBrand: string | null
      secondVaccinationDate: string | null
      secondVaccinationBrand: string | null
      updatedAt: string
    }
    ticketLineData: { documentNumber: string } | null
    splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
  }>
  seamanBooks: Array<{
    id: string
    bookNumber: string
    issueAt: string | null
    expiryAt: string | null
    isFlagStateSeamanBook: boolean
    nationalityAlpha2: string | null
  }>
  seamanPassports: Array<{
    id: string
    passportNumber: string
    passportIssuePlace: string | null
    passportNationalityIso: string
    passportIssueAt: string
    passportExpiryAt: string
  }>
  seamanVisas: Array<{
    id: string
    visaNumber: string
    visaName: string
    issueAt: string
    expiryAt: string
  }>
  seamanContracts: Array<{
    id: string
    hanseaticAssignmentId: number
    contractFrom: string
    contractUntil: string
  }>
  seamanRank: { name: string; sortOrder: number | null } | null
  seamanCommunications: Array<{ id: string; type: string; value: string | null }>
}

export type CompleteCrewChangeFragment = {
  bucketId: string
  completed: boolean
  archived: boolean
  temporary: boolean
  id: string
  startOn: string
  started: boolean
  vesselSchedule: {
    id: string
    sourceId: string | null
    cancelled: boolean
    ETA: string
    ETB: string
    ETD: string
    source: Types.VesselScheduleSource
    updatedAt: string
    crewChangeLinkage: { bucketId: string | null; isLinked: boolean } | null
    seaport: {
      id: string
      name: string
      nameAlpha2: string | null
      alternateNames: Array<string>
      countryAlpha2: string
      locode: string
      latitude: number
      longitude: number
      timezoneOlson: string | null
      quarantineRequirements: Array<{
        id: string
        vaccinationStatus: Types.VaccinationStatus
        quarantineTime: number
      }>
      covidTestingRequirements: Array<{
        id: string
        applicableTo: Types.VaccinationStatus
        timeReference: Types.TimeReference
        type: Array<string>
        travelPurpose: Types.TravelPurpose
      }>
    }
    updatedBy: {
      id: string
      email: string
      firstName: string | null
      lastName: string | null
      role: Types.UserRole
      phoneNumber: string | null
      city: string | null
      countryAlpha2: string | null
      timezoneOlson: string | null
      emailSignature: string | null
      fernandHash: string | null
    } | null
  }
  vessel: {
    activeCrewOperatorType: Types.ActiveCrewOperatorType
    activeCrewSupervisorType: Types.ActiveCrewSupervisorType
    id: string
    vesselScheduleSource: Types.VesselScheduleSource
    charterName: string
    imo: number
    vesselCommunicationDetails: Array<{ id: string; type: Types.CommunicationType; value: string }>
    manningAgencyUsersForVessels: Array<{
      manningAgencyUser: {
        manningAgencyId: string
        user: {
          id: string
          email: string
          firstName: string | null
          lastName: string | null
          role: Types.UserRole
          phoneNumber: string | null
          city: string | null
          countryAlpha2: string | null
          timezoneOlson: string | null
          emailSignature: string | null
          fernandHash: string | null
        }
      }
    }>
    primaryCrewSupervisor: {
      id: string
      email: string
      firstName: string | null
      lastName: string | null
      role: Types.UserRole
      phoneNumber: string | null
      city: string | null
      countryAlpha2: string | null
      timezoneOlson: string | null
      emailSignature: string | null
      fernandHash: string | null
    } | null
    backupCrewSupervisor: {
      id: string
      email: string
      firstName: string | null
      lastName: string | null
      role: Types.UserRole
      phoneNumber: string | null
      city: string | null
      countryAlpha2: string | null
      timezoneOlson: string | null
      emailSignature: string | null
      fernandHash: string | null
    } | null
    primaryCrewOperator: {
      id: string
      email: string
      firstName: string | null
      lastName: string | null
      role: Types.UserRole
      phoneNumber: string | null
      city: string | null
      countryAlpha2: string | null
      timezoneOlson: string | null
      emailSignature: string | null
      fernandHash: string | null
    } | null
    backupCrewOperator: {
      id: string
      email: string
      firstName: string | null
      lastName: string | null
      role: Types.UserRole
      phoneNumber: string | null
      city: string | null
      countryAlpha2: string | null
      timezoneOlson: string | null
      emailSignature: string | null
      fernandHash: string | null
    } | null
    additionalCrewOperatorsForVessel: Array<{
      crewOperator: {
        id: string
        email: string
        firstName: string | null
        lastName: string | null
        role: Types.UserRole
        phoneNumber: string | null
        city: string | null
        countryAlpha2: string | null
        timezoneOlson: string | null
        emailSignature: string | null
        fernandHash: string | null
      }
    }>
    crewManager: {
      id: string
      email: string
      firstName: string | null
      lastName: string | null
      role: Types.UserRole
      phoneNumber: string | null
      city: string | null
      countryAlpha2: string | null
      timezoneOlson: string | null
      emailSignature: string | null
      fernandHash: string | null
    } | null
  }
  seamen: Array<{
    id: string
    role: Types.CrewChangeRole
    active: boolean
    isSearchDeferred: boolean
    seamanId: string
    confirmationStatuses: Array<{
      id: string
      status: Types.SeamanConfirmationStatus
      user: {
        id: string
        email: string
        firstName: string | null
        lastName: string | null
        role: Types.UserRole
        phoneNumber: string | null
        city: string | null
        countryAlpha2: string | null
        timezoneOlson: string | null
        emailSignature: string | null
        fernandHash: string | null
      }
    }>
    crewChangeVisaRequirements: Array<{
      id: string
      createdAt: string
      isVisaRequired: boolean
      region: string
      visaRequiredOn: Types.VisaOrCovidTestRequiredOn
      additionalComments: string
      createdBy: {
        id: string
        email: string
        firstName: string | null
        lastName: string | null
        role: Types.UserRole
        phoneNumber: string | null
        city: string | null
        countryAlpha2: string | null
        timezoneOlson: string | null
        emailSignature: string | null
        fernandHash: string | null
      }
    }>
    manningAgency: { id: string; name: string } | null
    seaman: {
      id: string
      firstName: string
      lastName: string
      nationalityIso: string
      rank: number | null
      birthday: string | null
      placeOfBirth: string | null
      firstVaccinationDate: string | null
      firstVaccinationBrand: string | null
      secondVaccinationDate: string | null
      secondVaccinationBrand: string | null
      updatedAt: string
      preferredAirport: {
        isHomeAirport: boolean
        airport: {
          id: string
          alternativeIatas: Array<string>
          city: string
          countryAlpha2: string
          iata: string
          name: string
          timezoneOlson: string | null
          type: Types.AirportType | null
        }
      } | null
      manningAgency: { id: string } | null
      flightBookings: Array<{
        id: string
        active: boolean
        monitored: boolean
        ticketLine: string | null
        comment: string | null
        pnr: string | null
        status: Types.FlightBookingStatus
        amadeusSessionId: string | null
        managedByTravelTeam: boolean
        isAmadeus: boolean
        lastTicketingTime: string | null
        lastTicketingTimeSource: Types.LastTicketingTimeSource
        pendingUpdatedByTravelAgentNotification: boolean
        switchForIdenticalCheaperOfferAutomatically: boolean
        switchForSimilarCheaperOfferAutomatically: boolean
        ticketedAt: string | null
        bucketId: string | null
        autoTicket: boolean
        travelOfficeConfigurationId: string | null
        flight: {
          id: string
          updatedAt: string
          createdAt: string
          flightHash: string
          majorityCarrier: string | null
          travelOfficeConfigurationId: string
          validatingCarrier: string | null
          segments: Array<{
            id: string
            flightNumber: number
            index: number
            departure: {
              timezoneOlson: string | null
              time: string
              name: string | null
              iata: string
              countryAlpha2: string | null
              city: string | null
              date: string
              terminal: string | null
            }
            arrival: {
              timezoneOlson: string | null
              time: string
              name: string | null
              iata: string
              countryAlpha2: string | null
              city: string | null
              date: string
              terminal: string | null
            }
            marketingCarrier: {
              id: string
              createdAt: string
              updatedAt: string
              iata: string
              name: string
              logo: string | null
            } | null
            operatingCarrier: {
              id: string
              createdAt: string
              updatedAt: string
              iata: string
              name: string
              logo: string | null
            } | null
          }>
          travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
        }
        offer: {
          id: string
          amount: number
          tax: number | null
          passengerType: Types.PassengerType | null
          currency: string
          createdAt: string
          updatedAt: string
          flightId: string
          amadeusSessionId: string | null
          segmentFares: Array<{
            availableSeats: number | null
            fareTypes: Array<Types.FareType>
            fareBasisCode: string
            flightSegmentId: string
            bookingClass: string | null
            baggageDetails: {
              freeBagAllowance: number | null
              freeBagAllowanceType: Types.FreeBagAllowanceType | null
            }
          }>
          rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
          minirules: {
            beforeDeparture: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            beforeDepartureNoShow: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            afterDeparture: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            afterDepartureNoShow: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
          } | null
          taxDetails: Array<{
            amount: number
            code: string | null
            isNonRefundable: boolean | null
          }> | null
        }
        finalOffer: {
          amount: number
          currency: Types.Currency
          exchangeRate: { ratesToUsd: unknown } | null
        } | null
        seaman: {
          id: string
          firstName: string
          lastName: string
          nationalityIso: string
          rank: number | null
          birthday: string | null
          placeOfBirth: string | null
          firstVaccinationDate: string | null
          firstVaccinationBrand: string | null
          secondVaccinationDate: string | null
          secondVaccinationBrand: string | null
          updatedAt: string
        }
        ticketLineData: { documentNumber: string } | null
        splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
      }>
      seamanBooks: Array<{
        id: string
        bookNumber: string
        issueAt: string | null
        expiryAt: string | null
        isFlagStateSeamanBook: boolean
        nationalityAlpha2: string | null
      }>
      seamanPassports: Array<{
        id: string
        passportNumber: string
        passportIssuePlace: string | null
        passportNationalityIso: string
        passportIssueAt: string
        passportExpiryAt: string
      }>
      seamanVisas: Array<{
        id: string
        visaNumber: string
        visaName: string
        issueAt: string
        expiryAt: string
      }>
      seamanContracts: Array<{
        id: string
        hanseaticAssignmentId: number
        contractFrom: string
        contractUntil: string
      }>
      seamanRank: { name: string; sortOrder: number | null } | null
      seamanCommunications: Array<{ id: string; type: string; value: string | null }>
    }
  }>
  crewChangeSeamanDetail: Array<{
    id: string
    sourceId: string | null
    embarkationAt: string
    disembarkationAt: string
    contractEndAt: string | null
    role: Types.CrewChangeRole
    crewHash: string
    seamanId: string
  }> | null
  nearestAirport: {
    id: string
    alternativeIatas: Array<string>
    city: string
    countryAlpha2: string
    iata: string
    name: string
    timezoneOlson: string | null
    type: Types.AirportType | null
  }
  promotedSeamen: Array<{
    id: string
    role: Types.CrewChangeRole
    active: boolean
    isSearchDeferred: boolean
    seamanId: string
    confirmationStatuses: Array<{
      id: string
      status: Types.SeamanConfirmationStatus
      user: {
        id: string
        email: string
        firstName: string | null
        lastName: string | null
        role: Types.UserRole
        phoneNumber: string | null
        city: string | null
        countryAlpha2: string | null
        timezoneOlson: string | null
        emailSignature: string | null
        fernandHash: string | null
      }
    }>
    crewChangeVisaRequirements: Array<{
      id: string
      createdAt: string
      isVisaRequired: boolean
      region: string
      visaRequiredOn: Types.VisaOrCovidTestRequiredOn
      additionalComments: string
      createdBy: {
        id: string
        email: string
        firstName: string | null
        lastName: string | null
        role: Types.UserRole
        phoneNumber: string | null
        city: string | null
        countryAlpha2: string | null
        timezoneOlson: string | null
        emailSignature: string | null
        fernandHash: string | null
      }
    }>
    manningAgency: { id: string; name: string } | null
    seaman: {
      id: string
      firstName: string
      lastName: string
      nationalityIso: string
      rank: number | null
      birthday: string | null
      placeOfBirth: string | null
      firstVaccinationDate: string | null
      firstVaccinationBrand: string | null
      secondVaccinationDate: string | null
      secondVaccinationBrand: string | null
      updatedAt: string
      preferredAirport: {
        isHomeAirport: boolean
        airport: {
          id: string
          alternativeIatas: Array<string>
          city: string
          countryAlpha2: string
          iata: string
          name: string
          timezoneOlson: string | null
          type: Types.AirportType | null
        }
      } | null
      manningAgency: { id: string } | null
      flightBookings: Array<{
        id: string
        active: boolean
        monitored: boolean
        ticketLine: string | null
        comment: string | null
        pnr: string | null
        status: Types.FlightBookingStatus
        amadeusSessionId: string | null
        managedByTravelTeam: boolean
        isAmadeus: boolean
        lastTicketingTime: string | null
        lastTicketingTimeSource: Types.LastTicketingTimeSource
        pendingUpdatedByTravelAgentNotification: boolean
        switchForIdenticalCheaperOfferAutomatically: boolean
        switchForSimilarCheaperOfferAutomatically: boolean
        ticketedAt: string | null
        bucketId: string | null
        autoTicket: boolean
        travelOfficeConfigurationId: string | null
        flight: {
          id: string
          updatedAt: string
          createdAt: string
          flightHash: string
          majorityCarrier: string | null
          travelOfficeConfigurationId: string
          validatingCarrier: string | null
          segments: Array<{
            id: string
            flightNumber: number
            index: number
            departure: {
              timezoneOlson: string | null
              time: string
              name: string | null
              iata: string
              countryAlpha2: string | null
              city: string | null
              date: string
              terminal: string | null
            }
            arrival: {
              timezoneOlson: string | null
              time: string
              name: string | null
              iata: string
              countryAlpha2: string | null
              city: string | null
              date: string
              terminal: string | null
            }
            marketingCarrier: {
              id: string
              createdAt: string
              updatedAt: string
              iata: string
              name: string
              logo: string | null
            } | null
            operatingCarrier: {
              id: string
              createdAt: string
              updatedAt: string
              iata: string
              name: string
              logo: string | null
            } | null
          }>
          travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
        }
        offer: {
          id: string
          amount: number
          tax: number | null
          passengerType: Types.PassengerType | null
          currency: string
          createdAt: string
          updatedAt: string
          flightId: string
          amadeusSessionId: string | null
          segmentFares: Array<{
            availableSeats: number | null
            fareTypes: Array<Types.FareType>
            fareBasisCode: string
            flightSegmentId: string
            bookingClass: string | null
            baggageDetails: {
              freeBagAllowance: number | null
              freeBagAllowanceType: Types.FreeBagAllowanceType | null
            }
          }>
          rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
          minirules: {
            beforeDeparture: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            beforeDepartureNoShow: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            afterDeparture: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            afterDepartureNoShow: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
          } | null
          taxDetails: Array<{
            amount: number
            code: string | null
            isNonRefundable: boolean | null
          }> | null
        }
        finalOffer: {
          amount: number
          currency: Types.Currency
          exchangeRate: { ratesToUsd: unknown } | null
        } | null
        seaman: {
          id: string
          firstName: string
          lastName: string
          nationalityIso: string
          rank: number | null
          birthday: string | null
          placeOfBirth: string | null
          firstVaccinationDate: string | null
          firstVaccinationBrand: string | null
          secondVaccinationDate: string | null
          secondVaccinationBrand: string | null
          updatedAt: string
        }
        ticketLineData: { documentNumber: string } | null
        splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
      }>
      seamanBooks: Array<{
        id: string
        bookNumber: string
        issueAt: string | null
        expiryAt: string | null
        isFlagStateSeamanBook: boolean
        nationalityAlpha2: string | null
      }>
      seamanPassports: Array<{
        id: string
        passportNumber: string
        passportIssuePlace: string | null
        passportNationalityIso: string
        passportIssueAt: string
        passportExpiryAt: string
      }>
      seamanVisas: Array<{
        id: string
        visaNumber: string
        visaName: string
        issueAt: string
        expiryAt: string
      }>
      seamanContracts: Array<{
        id: string
        hanseaticAssignmentId: number
        contractFrom: string
        contractUntil: string
      }>
      seamanRank: { name: string; sortOrder: number | null } | null
      seamanCommunications: Array<{ id: string; type: string; value: string | null }>
    }
  }>
  portAgents: Array<{
    portAgent: {
      id: string
      email: string
      firstName: string | null
      lastName: string | null
      role: Types.UserRole
      phoneNumber: string | null
      city: string | null
      countryAlpha2: string | null
      timezoneOlson: string | null
      emailSignature: string | null
      fernandHash: string | null
    }
  }>
  crewChangeComments: Array<{
    id: string
    comment: string
    createdAt: string
    createdBy: {
      id: string
      email: string
      firstName: string | null
      lastName: string | null
      role: Types.UserRole
      phoneNumber: string | null
      city: string | null
      countryAlpha2: string | null
      timezoneOlson: string | null
      emailSignature: string | null
      fernandHash: string | null
    }
  }>
  crewChangeCovidRestrictions: Array<{
    id: string
    version: string
    data: unknown
    createdBy: {
      id: string
      email: string
      firstName: string | null
      lastName: string | null
      role: Types.UserRole
      phoneNumber: string | null
      city: string | null
      countryAlpha2: string | null
      timezoneOlson: string | null
      emailSignature: string | null
      fernandHash: string | null
    }
  }>
}

export const CompleteSeamanDetailsFragmentDoc = gql`
  fragment CompleteSeamanDetails on Seaman {
    ...CompleteSeaman
    preferredAirport {
      airport {
        ...AirportFields
      }
      isHomeAirport
    }
    manningAgency {
      id
    }
    flightBookings(bucketId: $bucketId) {
      ...CompleteFlightBooking
    }
  }
  ${CompleteSeamanFragmentDoc}
  ${AirportFieldsFragmentDoc}
  ${CompleteFlightBookingFragmentDoc}
`
export const CompleteCrewChangeSeamanFragmentDoc = gql`
  fragment CompleteCrewChangeSeaman on CrewChangeSeaman {
    id
    role
    active
    isSearchDeferred
    seamanId
    confirmationStatuses {
      id
      status
      user {
        ...SafeUserFields
      }
    }
    crewChangeVisaRequirements {
      id
      createdAt
      isVisaRequired
      region
      visaRequiredOn
      additionalComments
      createdBy {
        ...SafeUserFields
      }
    }
    manningAgency {
      id
      name
    }
    seaman {
      ...CompleteSeamanDetails
    }
  }
  ${SafeUserFieldsFragmentDoc}
  ${CompleteSeamanDetailsFragmentDoc}
`
export const CompleteCrewChangeFragmentDoc = gql`
  fragment CompleteCrewChange on CrewChange {
    bucketId
    completed
    archived
    temporary
    id
    startOn
    started
    vesselSchedule {
      id
      sourceId
      cancelled
      ETA
      ETB
      ETD
      source
      crewChangeLinkage {
        bucketId
        isLinked
      }
      seaport {
        ...SeaportFields
        quarantineRequirements {
          id
          vaccinationStatus
          quarantineTime
        }
        covidTestingRequirements {
          id
          applicableTo
          timeReference
          type
          travelPurpose
        }
      }
      updatedAt
      updatedBy {
        ...SafeUserFields
      }
    }
    vessel {
      ...VesselFields
      vesselCommunicationDetails {
        id
        type
        value
      }
      manningAgencyUsersForVessels {
        manningAgencyUser {
          manningAgencyId
          user {
            ...SafeUserFields
          }
        }
      }
      activeCrewOperatorType
      activeCrewSupervisorType
      primaryCrewSupervisor {
        ...SafeUserFields
      }
      backupCrewSupervisor {
        ...SafeUserFields
      }
      primaryCrewOperator {
        ...SafeUserFields
      }
      backupCrewOperator {
        ...SafeUserFields
      }
      additionalCrewOperatorsForVessel {
        crewOperator {
          ...SafeUserFields
        }
      }
      crewManager {
        ...SafeUserFields
      }
    }
    seamen {
      ...CompleteCrewChangeSeaman
    }
    crewChangeSeamanDetail {
      id
      sourceId
      embarkationAt
      disembarkationAt
      contractEndAt
      role
      crewHash
      seamanId
    }
    nearestAirport {
      ...AirportFields
    }
    promotedSeamen {
      ...CompleteCrewChangeSeaman
    }
    portAgents {
      portAgent {
        ...SafeUserFields
      }
    }
    crewChangeComments {
      id
      comment
      createdAt
      createdBy {
        ...SafeUserFields
      }
    }
    crewChangeCovidRestrictions {
      id
      version
      data
      createdBy {
        ...SafeUserFields
      }
    }
  }
  ${SeaportFieldsFragmentDoc}
  ${SafeUserFieldsFragmentDoc}
  ${VesselFieldsFragmentDoc}
  ${CompleteCrewChangeSeamanFragmentDoc}
  ${AirportFieldsFragmentDoc}
`
