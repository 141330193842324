// This is a temporary workaround for our type definitions so that we can have some type
// safety while building the UI. Ideally, this plugs into Prisma more naturally

import type { LogDataSyncRequest, Tenant, User, Vessel } from '@tilla/prisma'

export { Role } from '@tilla/backend/shared'

export type LandingVessel = Vessel

export interface VesselDetails {
  id: string
  hanseaticVesselId: number
  callSign: string
  charterName: string
  internalCode: string
  imagePath: string
  grossTonnage: number
  accountId: string
  createdAt: string
  updatedAt: string
}

export interface TravelRestrictionsFlightLeg {
  departure: {
    date: string
    time: string
    iata: string
  }
  arrival: {
    date: string
    time: string
    iata: string
  }
}

export interface TravelRestrictionSearchParameters {
  trip: {
    nationality: string
    legs: TravelRestrictionsFlightLeg[]
  }
}

export type UserSummary = Pick<
  User,
  | 'id'
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'role'
  | 'phoneNumber'
  | 'city'
  | 'countryAlpha2'
  | 'timezoneOlson'
  | 'emailSignature'
>

export type TenantSummary = Pick<Tenant, 'id' | 'name'>

export type CrewChangeDocumentUploadDetail = {
  seamen: {
    id: string
    name: string
    seamanId: string
  }[]
  document: {
    documentName: string
    fileType: string
  }
}

export type CrewChangeTab = 'port-planning' | 'crew-changes' | 'edit'

export type LandingTab = 'vessels' | 'pnr' | 'vessel-schedules'

export enum MessageCategory {
  Information = 'information',
  Warning = 'warning',
  Critical = 'critical',
}

export type PortPlaningSummary = {
  travelRestrictions: {
    value: string
    category: MessageCategory
  }
  visaRequirement: {
    value: string
    category: MessageCategory
  }
  quarantineRequirement: {
    value: string
    category: MessageCategory
  }
  covidTesting: {
    value: string
    category: MessageCategory
  }
  isPortOpen: boolean | null
  handoverTime: {
    value: string
    category: MessageCategory
  }
}

export type DateSyncLogDetail = LogDataSyncRequest & {
  user: UserSummary
}

export enum TimezoneOptions {
  Utc = 'UTC',
  Port = 'Port',
}
