import clsx from 'clsx'
import React, { forwardRef } from 'react'
import CheckMarkIcon from '../Icon/CheckMarkIcon'
import { UiSizes, UiVariants } from '../types'

type CheckBoxVariants = Exclude<UiVariants, 'loading' | 'danger-outline' | 'outline'>

interface FormCheckboxProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size' | 'onClick'> {
  label?: React.ReactNode
  size?: UiSizes
  variant?: CheckBoxVariants
}

const sizeClasses: Record<UiSizes, string> = {
  custom: '',
  small: 'h-4 w-4',
  regular: 'h-5 w-5',
  large: 'h-6 w-6',
}

const labelSizeClasses: Record<UiSizes, string> = {
  custom: '',
  small: 'text-xs',
  regular: 'text-sm',
  large: 'text-base',
}

const variantClasses: Record<CheckBoxVariants, string> = {
  custom: '',
  default: 'border-neutral-200 checked:bg-indigo-500 disabled:bg-indigo-300',
  success: 'border-green-200 checked:bg-green-500 disabled:bg-green-300',
  danger: 'border-red-200 checked:bg-red-500 disabled:bg-red-300',
  warning: 'border-yellow-200 checked:bg-yellow-500 disabled:bg-yellow-300',
}

const FormCheckbox = forwardRef<HTMLInputElement, FormCheckboxProps>(
  ({ id, label, className, size = 'regular', variant = 'default', ...props }, ref) => {
    return (
      <label
        htmlFor={id}
        className={clsx(
          'flex items-center p-3 -mx-3 leading-none rounded-md cursor-pointer',
          labelSizeClasses[size],
          className
        )}
      >
        <div
          className={clsx('relative inline-flex items-center justify-center', sizeClasses[size])}
        >
          {/* The readonly prop doesn't actually work with checkboxes, so to get around that we make a dummy checkbox with a div */}
          {props.readOnly ? (
            <div
              id={id}
              className={clsx(
                'peer appearance-none border-2 rounded-sm cursor-pointer',
                'focus:outline-none',
                props.checked ? 'bg-indigo-500 border-transparent' : '',
                sizeClasses[size],
                variantClasses[variant]
              )}
            />
          ) : (
            <input
              type="checkbox"
              id={id}
              ref={ref}
              {...props}
              // Prevent the click event from bubbling up to the parent.
              // Click events should be handled by onChange only (if available)
              onClick={(ev) => {
                if (props.onChange) {
                  ev.stopPropagation()
                }
              }}
              className={clsx(
                'peer appearance-none border-2 rounded-sm cursor-pointer',
                'checked:border-transparent focus:outline-none',
                sizeClasses[size],
                variantClasses[variant]
              )}
            />
          )}

          <CheckMarkIcon
            size={size}
            className={clsx(
              'absolute pointer-events-none opacity-0 transition-opacity duration-200',
              'peer-checked:opacity-100 text-white',
              props.checked ? 'opacity-100' : 'opacity-0'
            )}
          />
        </div>

        {label && <span className="ml-2">{label}</span>}
      </label>
    )
  }
)

FormCheckbox.displayName = 'FormCheckbox'

export default FormCheckbox
