import { Role } from 'types'
import { Me } from '~context/UserContext'
import { isProd } from './checkEnv'

/**
 * @description Determines whether or not a particular user is allowed access to decision making
 * @param user User
 * @returns boolean - true if allowed access, false otherwise
 */
export const canViewDecisionMaking = (user: Me): boolean => {
  if (
    isProd() &&
    ([
      '23319881-6857-4aba-ab40-f482fba12a5f',
      '5dbc40b4-9a73-4ab2-90b9-d3df82736a5f',
      'a14bc38b-9b96-4a5c-9e3f-c7155f14d8c3',
      'fb17fdd2-95af-46a6-bd15-e2dbc8cf09a5',
      '3f8a07df-4022-47e8-9f0b-8e8799a19621',
      '5091e7a2-e202-4203-838a-876d61ed2589',
      '88bae0bf-8345-4a83-94bc-1c3f0cce738a',
      '4795d8b8-c146-428d-b229-5972f9d111f0',
      'e16eef9a-4c92-48f5-a90e-dd0660452bc4',
      '99af70ef-0541-4370-9ba9-a5417d910133'
    ].includes(user.id) ||
      user.role === Role.TillaBackoffice)
  ) {
    return true
  }

  return !isProd()
}
