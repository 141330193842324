import { ActiveCrewOperatorType, ActiveCrewSupervisorType } from 'api/types.generated'
import { uniqBy } from 'lodash'
import { DateTime } from 'luxon'
import Head from 'next/head'
import { useContext, useEffect, useMemo, useState } from 'react'
import { Role } from 'types'
import getCrewChangesSearchParams from 'utils/crewChanges/getCrewChangesSearchParams/getCrewChangesSearchParams'
import { CrewChangesDateFilteringOption } from 'utils/crewChanges/types'
import ClientOnly from '~components/ClientOnly'
import CrewChangeListFilter from '~components/CrewChanges/CrewChangeListFilter'
import CrewChangesDateFilter from '~components/CrewChanges/CrewChangesDateFilter'
import CrewChangeTable from '~components/CrewChanges/CrewChangesTable'
import FlightsWarningBanner from '~components/CrewChanges/FlightsWarningBanner'
import TabBarHeader from '~components/ui/TabBar/TabBarHeader'
import TabBarNavigation from '~components/ui/TabBar/TabBarNavigation'
import TabBarNavigationItem from '~components/ui/TabBar/TabBarNavigationItem'
import { CrewChangeOverviewFilterContext } from '~context/CrewChangeOverviewFilterContext'
import { UserContext } from '~context/UserContext'
import useIsBackofficeUser from '~hooks/useIsBackofficeUser'
import { useTrackEvent } from '~hooks/useTrackEvent'
import { useLoadCrewChangesQuery } from '../api/crew-changes-gql.generated'

export enum CrewChangeOverViewTab {
  Clean = 'Clean',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  Archived = 'Archived',
}

export default function CrewChanges() {
  const [activeTab, setActiveTab] = useState<CrewChangeOverViewTab>(CrewChangeOverViewTab.Clean)
  const [dateFilterOption, setDateFilterOption] = useState<CrewChangesDateFilteringOption>(
    CrewChangesDateFilteringOption.Current
  )

  const { user } = useContext(UserContext)
  const isBackofficeUser = useIsBackofficeUser()

  const searchFilters = useMemo(
    () => ({
      completed: activeTab === CrewChangeOverViewTab.Completed,
      archived: activeTab === CrewChangeOverViewTab.Archived,
      cancelled: activeTab === CrewChangeOverViewTab.Cancelled,
      dateFilterOption,
    }),
    [activeTab, dateFilterOption]
  )

  const loadParams = useMemo(() => getCrewChangesSearchParams(searchFilters), [searchFilters])

  const { data, loading } = useLoadCrewChangesQuery({
    variables: loadParams,
    onError: () => undefined,
    fetchPolicy: 'cache-and-network',
  })

  const crewChanges = useMemo(() => data?.crewChanges ?? [], [data?.crewChanges])

  const { data: cancelledCrewChangesData } = useLoadCrewChangesQuery({
    variables: getCrewChangesSearchParams({
      completed: false,
      dateFilterOption,
      cancelled: true,
      archived: false,
    }),
    onError: () => undefined,
    fetchPolicy: 'cache-and-network',
  })

  const cancelledCrewChanges = useMemo(
    () => cancelledCrewChangesData?.crewChanges ?? [],
    [cancelledCrewChangesData?.crewChanges]
  )

  const { data: archivedCrewChangesData } = useLoadCrewChangesQuery({
    variables: getCrewChangesSearchParams({
      completed: false,
      dateFilterOption,
      cancelled: false,
      archived: true,
    }),
    onError: () => undefined,
    fetchPolicy: 'cache-and-network',
  })

  const archivedCrewChanges = useMemo(
    () => archivedCrewChangesData?.crewChanges ?? [],
    [archivedCrewChangesData?.crewChanges]
  )

  const { filters, setFiltersForTabAndType } = useContext(CrewChangeOverviewFilterContext)

  useEffect(() => {
    setFiltersForTabAndType(
      'crewOperator',
      uniqBy(
        crewChanges.map((cc) => {
          return cc.activeCrewOperatorType === ActiveCrewOperatorType.Primary
            ? {
                id: cc.primaryCrewOperatorId ?? '',
                name: cc.primaryCrewOperatorName ?? '',
                selected: true,
              }
            : {
                id: cc.backupCrewOperatorId ?? '',
                name: cc.backupCrewOperatorName ?? '',
                selected: true,
              }
        }),
        'id'
      ),
      activeTab,
      user.id
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crewChanges])

  useEffect(() => {
    setFiltersForTabAndType(
      'crewSupervisor',
      uniqBy(
        crewChanges.map((cc) => {
          return cc.activeCrewSupervisorType === ActiveCrewSupervisorType.Primary
            ? {
                id: cc.primaryCrewSupervisorId ?? '',
                name: cc.primaryCrewSupervisorName ?? '',
                selected: true,
              }
            : {
                id: cc.backupCrewSupervisorId ?? '',
                name: cc.backupCrewSupervisorName ?? '',
                selected: true,
              }
        }),
        'id'
      ),
      activeTab,
      user.id
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crewChanges])

  useEffect(() => {
    setFiltersForTabAndType(
      'vessel',
      uniqBy(
        crewChanges.map((cc) => {
          return {
            id: cc.vesselId,
            name: cc.vesselName,
            selected: true,
          }
        }),
        'id'
      ),
      activeTab,
      user.id
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crewChanges])

  const dateFilterLabels = useMemo(() => {
    let current =
      activeTab === CrewChangeOverViewTab.Completed ? 'Recently completed' : 'Current crew changes'
    let all = activeTab === CrewChangeOverViewTab.Completed ? 'All completed' : 'All crew changes'

    return {
      current,
      all,
    }
  }, [activeTab])

  const dateFilterOptions = useMemo(() => {
    const current = {
      value: CrewChangesDateFilteringOption.Current,
      label: dateFilterLabels.current,
    }

    const all = {
      value: CrewChangesDateFilteringOption.All,
      label: dateFilterLabels.all,
    }

    return [all, current]
  }, [dateFilterLabels])

  const trackEvent = useTrackEvent()

  useEffect(() => {
    const viewTimeStart = DateTime.now()
    trackEvent({ event: 'Viewed overview screen' })
    return () => {
      trackEvent({
        event: 'Time on overview screen',
        metadata: {
          mixpanelProperties: {
            duration: DateTime.now().diff(viewTimeStart, ['hours', 'minutes', 'seconds']).toHuman(),
          },
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ClientOnly>
      <Head>
        <title>Crew Changes</title>
      </Head>
      <div className="w-full space-y-6">
        <FlightsWarningBanner
          archivedCrewChanges={archivedCrewChanges}
          cancelledCrewChanges={cancelledCrewChanges}
          showArchivedCrewChanges={() => setActiveTab(CrewChangeOverViewTab.Archived)}
          showCancelledCrewChanges={() => setActiveTab(CrewChangeOverViewTab.Cancelled)}
        />

        <div className="lg:flex lg:justify-between lg:items-center">
          <h1 className="flex text-2xl font-bold leading-none text-gray-800">Overview</h1>
        </div>

        <TabBarHeader
          fixedHeight={false}
          inset={false}
          className="flex-col-reverse lg:flex-row justify-between"
        >
          <TabBarNavigation className="w-full lg:w-auto">
            <TabBarNavigationItem
              isActive={activeTab === CrewChangeOverViewTab.Clean}
              onClick={() => setActiveTab(CrewChangeOverViewTab.Clean)}
            >
              Active
            </TabBarNavigationItem>
            <TabBarNavigationItem
              isActive={activeTab === CrewChangeOverViewTab.Completed}
              onClick={() => setActiveTab(CrewChangeOverViewTab.Completed)}
            >
              Completed
            </TabBarNavigationItem>
            <TabBarNavigationItem
              isActive={activeTab === CrewChangeOverViewTab.Cancelled}
              onClick={() => setActiveTab(CrewChangeOverViewTab.Cancelled)}
            >
              Cancelled
            </TabBarNavigationItem>

            {isBackofficeUser && (
              <TabBarNavigationItem
                isActive={activeTab === CrewChangeOverViewTab.Archived}
                onClick={() => setActiveTab(CrewChangeOverViewTab.Archived)}
              >
                Archived
              </TabBarNavigationItem>
            )}
          </TabBarNavigation>

          <div className="flex items-center justify-between w-full lg:justify-normal lg:w-fit lg:gap-4">
            <div className="flex flex-wrap gap-2 lg:flex-nowrap lg:space-x-2">
              <CrewChangeListFilter tab={activeTab} type="vessel" title="Vessel" />

              {user.role === Role.TillaBackoffice && (
                <CrewChangeListFilter tab={activeTab} type="crewOperator" title="Crew Operator" />
              )}

              {user.role === Role.TillaBackoffice && (
                <CrewChangeListFilter
                  tab={activeTab}
                  type="crewSupervisor"
                  title="Crew Superintendent"
                />
              )}
            </div>

            <CrewChangesDateFilter onSelected={setDateFilterOption} options={dateFilterOptions} />
          </div>
        </TabBarHeader>

        <CrewChangeTable
          activeTab={activeTab}
          crewChanges={crewChanges
            .filter((cc) =>
              filters[activeTab].vessel
                .filter((v) => v.selected)
                .map((v) => v.id)
                .includes(cc.vesselId)
            )
            .filter((cc) => {
              if (user.role !== Role.TillaBackoffice) {
                return true
              }

              const activeCrewOperator =
                cc.activeCrewOperatorType === ActiveCrewOperatorType.Primary
                  ? cc.primaryCrewOperatorId
                  : cc.backupCrewOperatorId
              return filters[activeTab].crewOperator
                .filter((v) => v.selected)
                .map((v) => v.id)
                .includes(activeCrewOperator ?? '')
            })
            .filter((cc) => {
              if (user.role !== Role.TillaBackoffice) {
                return true
              }

              const activeCrewSupervisor =
                cc.activeCrewSupervisorType === ActiveCrewSupervisorType.Primary
                  ? cc.primaryCrewSupervisorId
                  : cc.backupCrewSupervisorId
              return filters[activeTab].crewSupervisor
                .filter((v) => v.selected)
                .map((v) => v.id)
                .includes(activeCrewSupervisor ?? '')
            })}
          loading={loading}
        />
      </div>
    </ClientOnly>
  )
}
